import sycamoreServiceClient from '.';

export const getSycamoreContacts = async () => {
  const response = await sycamoreServiceClient({
    url: "/customer/sycamore/variables",
    method: 'get',
  });

  return response.data
};
