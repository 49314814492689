<template>
  <div>
    <div class="h-screen flex items-center" v-if="loading">
      <div class="m-auto">
        <Ripple :isLoading="loading" />
      </div>
    </div>

    <div v-else class="bg-white lg:mx-60">
      <div class="flex justify-between items-center mb-12 pr-4 lg:pr-12">
        <div class="w-5/12 lg:w-4/12">
          <img src="../assets/images/letterhead_logo.png" />
        </div>

        <div class="">
          <p>
            {{ moment(new Date()).format("Do MMMM YYYY") }}
          </p>
        </div>
      </div>

      <div class="md:w-10/12 m-auto">
        <div class="mt-12 px-4 lg:px-12">
          <div class="flex flex-col text-xs md:text-sm mb-5">
            <div class="mb-4">
              <p class="">{{customerData.address}}</p>
              <p class="capitalize my-5">Dear Sir/Madam,</p>
            </div>

            <div>
              <p class="font-bold underline uppercase mb-4 text-lg">
                {{
                  `CONFIRMATION OF INVESTMENT IN FAVOUR OF ${customerData.Customer.name}`
                }}
              </p>
              <p class="mb-4">
                We hereby confirm that the above-named individual is a client of
                Sycamore Integrated Solutions Limited (SISL).
              </p>
              <p class="mb-4">
                Sycamore Integrated Solutions Limited (SISL) is incorporated and
                licensed by the Lagos State Government as a government approved
                money lender, rendering Fund/Portfolio Management Services. SISL
                aims to offer its clientele competitive portfolio yield, safety
                of funds, adequate reporting, and premium investment advisory.
              </p>
              <p class="mb-4">
                {{`The client (${customerData.Customer.name}) had investments managed by Sycamore
                Integrated Solutions Limited valued at ₦${Number(customerData.principal_amount.toFixed(2)).toLocaleString()} for the period of ${customerData.tenure} ${customerData.tenure_type} from the ${moment(customerData.start_date).format("Do MMMM YYYY")} to the ${moment(customerData.end_date).format("Do MMMM YYYY")}`}}
              </p>
              <p class="mb-4">
                Please note that the investment is still running as at today.
                This information is strictly confidential and provided with the
                consent of the investor.
              </p>
              <p class="mb-4">
                Please direct all enquiries to the address stated below.
              </p>
            </div>

            <div class="mb-6">
              <table class="">
                <thead>
                  <tr class="text-xs md:text-sm">
                    <th class="text-xs md:text-sm border font-bold">NAME</th>
                    <th class="text-xs md:text-sm border font-bold">
                      PHONENUMBER
                    </th>
                    <th class="text-xs md:text-sm border font-bold">
                      EMAIL ADDRESS
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td class="text-xs md:text-sm border">
                      Babatunde Akin-Moses
                    </td>
                    <td class="text-xs md:text-sm border">
                      +234(0)80 6335 2208
                    </td>
                    <td class="text-xs md:text-sm border">tunde@sycamore.ng</td>
                  </tr>
                  <tr>
                    <td class="text-xs md:text-sm border">
                      Onyinyechukwuka Okonji
                    </td>
                    <td class="text-xs md:text-sm border">
                      +234(0)81 2430 3989
                    </td>
                    <td class="text-xs md:text-sm border">
                      onyinye@sycamore.ng
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div class="mb-6">
              <p class="text-xs md:text-sm">Yours Sincerely,</p>

              <p class="text-xs md:text-sm font-semibold mt-3 mb-4">
                For: Sycamore Integrated Solutions Limited
              </p>

              <div class="columns is-mobile">
                <div class="column is-5">
                  <div class="w-6/12 md:w-4/12">
                    <img
                      src="../assets/signature/tunde.svg"
                      width="400"
                      alt=""
                    />
                  </div>
                  <div>
                    <p class="text-xs md:text-sm">Babatunde Akin-Moses</p>
                    <p class="text-xs md:text-sm">Managing Director</p>
                  </div>
                </div>

                <div
                  class="column is-4-tablet is-offset-4-tablet is-5-mobile is-offset-2-mobile"
                >
                  <div class="flex justify-start">
                    <div class="w-4/12 md:w-3/12">
                      <img
                        src="../assets/signature/onyinye.svg"
                        class=""
                        alt=""
                      />
                    </div>
                  </div>
                  <div class="text-left">
                    <p class="text-xs md:text-sm">Onyinye Okonji</p>
                    <p class="text-xs md:text-sm">Executive Director</p>
                  </div>
                </div>
              </div>
            </div>

            <hr />
            <div class="text-center mt-4 pb-4">
              <p class="text-xs md:text-sm leading-6 ">
                <span class="font-semibold">The Board:</span> Bade Aluko | Adeola Azeez | Oliver Nnona
              </p>
              <p class="text-xs md:text-sm leading-6 mt-1">
                <span class="font-semibold">Executive Directors:</span> Babatunde Akin-Moses | Onyinye Okonji |
                Mayowa Adeosun
              </p>
              <p v-if="contacts" class="text-xs md:text-sm font-semibold mt-1">
                {{ contacts.address }} | <a :href="`mailto:${contacts.alt_contact_email}`" class="underline hover:text-primary text-primary">{{ contacts.alt_contact_email }}</a> |
                {{ contacts.customer_service_number }} | <a href="www.sycamore.ng" target='_blank' class="underline hover:text-primary text-primary">www.sycamore.ng</a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Ripple from "@/components/Ripple";
import moment from "moment";
import { getSycamoreContacts } from "@/api/contacts";

export default {
  name: "ProofOfInvestment",
  components: {
    Ripple,
  },

  data() {
    return {
      loading: false,
      customerData: {},

      contacts: null,
    };
  },

  methods: {
    getInvestment() {
      this.loading = true;
      const { offer_link } = this.$route.params;
      axios
        .get(
          `${process.env.VUE_APP_API_URL}/investment/proof/${offer_link}`
        )
        .then((response) => {
          this.customerData = response.data.data;
        })
        .catch((error) => {
          this.errorStatus = error;
        })
        .finally(() => {
          this.loading = false;
        });
    },

    async getContacts() {
      const response = await getSycamoreContacts();
      this.contacts = response.data.contact;
    },

    moment: function (date) {
      return moment(date);
    },
  },

  mounted() {
    this.getInvestment();
    this.getContacts();
  },
};
</script>

<style scoped>
tr:nth-child(even) {
    background-color: #FFFF;
}
</style>