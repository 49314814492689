<template>
    <div>
        <div class="h-screen flex items-center" v-if="loading">
            <div class="m-auto">
                <Ripple :isLoading="loading" />
            </div>
        </div>
        
        <div v-else class="bg-white lg:mx-60">
            <div v-if="activeTab == 0">
                <div class="flex justify-between items-center mb-12 pr-4 lg:pr-12">
                    <div class="w-5/12 lg:w-4/12">
                        <img src="../../assets/images/letterhead_logo.png">
                    </div>

                    <div class="">
                        <span class="text-black text-xs md:text-sm">
                            Offer letter {{ customerData ? customerData.contract_status : '' }}
                        </span>
                        <p class="text-xs md:text-sm">RC : 1505897</p>
                    </div>
                </div>

                <div class="md:w-10/12 m-auto">
                    <div class="mt-12 px-4 lg:px-12">
                        <div class="flex flex-col text-xs md:text-sm mb-5">
                            <div class="mb-6">
                                <p class="">
                                    {{customerData ? moment(customerData.start_date).format('DD MMMM YYYY') : 'N/A'}}. 
                                </p>
                                <p class="">
                                    Policy No:
                                    <span>{{ customerData.contribution_id || 'No policy number' }}</span>
                                </p>
                                <p class="font-bold capitalize">
                                    Dear {{ customerData.Customer ? customerData.Customer.name : 'No name' }}
                                </p>
                            </div>
                            
                            <div class="text-center">
                                <p class="font-semibold">RE: Target Contribution Agreement with Sycamore Integrated Services Limited </p>
                                <p class="" v-if="customerData.start_date">We are pleased to confirm receipt of your funds with us on the following terms and conditions: </p>
                                <p class="" v-else>We are pleased to offer you this proposed agreement. Kindly review and confirm if the terms below are acceptable to you. </p>
                            </div>
                        </div>

                    <div class="mb-6">
                        <table class="">
                            <thead>
                                <tr class="text-xs md:text-sm">
                                    <th class="text-xs md:text-sm border-t-0 border-r-0 border-l-0 border-b-2">Contribution Type </th>
                                    <th class="text-xs md:text-sm border-t-0 border-b-2 border-r-0 border-l-0 font-normal">{{(customerData.target_type === 'fixed' ? 'Goals' : 'Flex Purse') || 'N/A'}}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td class="text-xs md:text-sm border-t-0 border-r-0 borde border-l-0 border-b-0 font-semibold">Target Amount</td>
                                    <td class="text-xs md:text-sm border-t-0 border-b-0 border-r-0 border-l-2">₦{{ customerData.expected_target_amount ?  Number(customerData.expected_target_amount.toFixed(2)).toLocaleString() : 'N/A'}}</td>
                                </tr>
                                <tr>
                                    <td class="text-xs md:text-sm border-t-0 border-r-0 borde border-l-0 border-b-0 font-semibold">
                                        Topup Amount
                                    </td>
                                    <td class="text-xs md:text-sm border-t-0 border-b-0 border-r-0 border-l-2">
                                        ₦{{ customerData.topup_amount ? Number(customerData.topup_amount.toFixed(2)).toLocaleString() : 'N/A' }}
                                    </td>
                                </tr>
                                 <tr>
                                    <td class="text-xs md:text-sm bg-transparent border-t-0 border-r-0 borde border-l-0 border-b-0 font-semibold">Start Date</td>
                                    <td class="text-xs md:text-sm border-t-0 border-b-0 border-r-0 border-l-2">{{customerData.start_date ? moment(customerData.start_date).format('DD MMMM YYYY') : 'N/A'}}</td>
                                </tr>
                               <tr>
                                    <td class="text-xs md:text-sm bg-transparent border-t-0 border-r-0 borde border-l-0 border-b-0 font-semibold">Maturity Date</td>
                                    <td class="text-xs md:text-sm border-t-0 border-b-0 border-r-0 border-l-2">{{ customerData.maturity_date ? moment(customerData.maturity_date).format('DD MMMM YYYY') : 'N/A'}}</td>
                                </tr>
                                 <tr>
                                    <td class="text-xs md:text-sm border-t-0 border-r-0 borde border-l-0 border-b-0 font-semibold">Payment Pattern</td>
                                    <td class="text-xs md:text-sm border-t-0 border-b-0 border-r-0 border-l-2 capitalize">{{customerData.topup_frequency ? customerData.topup_frequency : 'N/A'}}</td>
                                </tr>
                                <tr>
                                    <td class="text-xs md:text-sm border-t-0 border-r-0 borde border-l-0 border-b-0 font-semibold">Tenor</td>
                                    <td class="text-xs md:text-sm border-t-0 border-b-0 border-r-0 border-l-2">{{`${customerData.tenor} ${this.tenure(customerData.topup_frequency)}`}}</td>
                                </tr>
                                <tr>
                                    <td class="text-xs md:text-sm border-t-0 border-r-0 borde border-l-0 border-b-0 font-semibold">Interest Rate (p.a.)</td>
                                    <td class="text-xs md:text-sm border-t-0 border-b-0 border-r-0 border-l-2">{{customerData.interest_rate || 'N/A'}}%</td>
                                </tr>
                                <tr>
                                    <td class="text-xs md:text-sm border-t-0 border-r-0 borde border-l-0 border-b-0 font-semibold">Withholding Tax Rate</td>
                                    <td class="text-xs md:text-sm border-t-0 border-b-0 border-r-0 border-l-2">{{ customerData.wht_rate || 'N/A'}}%</td>
                                </tr>
                                <tr>
                                    <td class="text-xs md:text-sm border-t-0 border-r-0 borde border-l-0 border-b-0 font-semibold">
                                        Lock
                                    </td>
                                    <td class="text-xs md:text-sm border-t-0 border-b-0 border-r-0 border-l-2">
                                        {{ customerData.lock }}
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>

                        <div class="mb-6">
                            <div class="ml-4">
                                <ol class="list-outside list-decimal text-xs lg:text-sm">
                                    <li>
                                        <h3 class="text-md font-semibold pb-2">TERMS OF USE</h3>
                                        <ol class="ml-3 list-outside list-decimal space-y-3">
                                            <li>
                                                Sycamore Integrated Solutions Limited (“SISL’, “we”,“us, or “our") is a financial technology company incorporated in Nigeria and carrying on business at 53B, Oduduwa Street, Ikeja, Lagos State.
                                            </li>

                                            <li>
                                                The company, through its mobile application and all its digital channels (collectively, “Platform*) operates as a co-operative scheme, and a financial solutions company in Lagos State. The use of our Platform and services are subject to the following terms and conditions, as may be amended from time to time (the “Terms”).
                                            </li>

                                            <li>
                                                Our Privacy Notice and Acceptable Use Policy are incorporated into these Terms. The Terms constitute a legal agreement between you and Sycamore Finance. Please review the Terms carefully as it governs your use of our Platform and services.
                                            </li>

                                            <li>
                                                The Terms only apply to all users of our Platform, including but not limited to, users who are customers, merchants, target contributors. If you access and use our Platform or our services, you accept and agree to be bound by and comply with the Terms, our Acceptable Use Policy, and our Privacy Notice. If you accept these Terms on behalf of another person or entity, you represent and guarantee that you are authorized to do so. If you do not agree to the Terms, our Acceptable Use Policy, or our Privacy Notice, please do not use our Platform or services.
                                            </li>
                                        </ol>
                                    </li>

                                    <li class="mt-5">
                                        <h3 class="text-md font-semibold pb-2"> We hereby grant you an exclusive license to use our Platform for target contributions, or other any financial product on the app. All collections, contributions, payments, deemed investments or fees are chargeable and registered to our Co-operative Society as Contributions''</h3>
                                        <ol class="ml-3 list-outside list-decimal space-y-3">
                                            <li>
                                                When you request a service or product from us, you agree to provide us with accurate personal information, such as your email address, utility bills, phone number and other contact details to enable us to provide the product or service to you or to exercise any of our rights against you. If you change any information provided to us, you agree to promptly notify us of such change and to update your account.
                                            </li>

                                            <li>
                                                While we make all reasonable efforts to ensure that our Platform is always available, we do not guarantee that the Platform will be available or be uninterrupted. Therefore, we shall not be liable to you for any loss or damage which you may suffer because of any suspension, restriction, or unavailability of our Platform whether owing to a fault, error, damage, or planned maintenance.
                                            </li>

                                            <li>
                                                Also note that while we use reasonable due diligence to ensure that information provided on our Platform—including statistical information and market research data—is accurate and complete, we do not undertake to update, modify or clarify information on our Platform, except as required by law, and we do not accept liability, however caused, arising from any information, which is incorrect, incomplete, outdated, or superseded.
                                            </li>
                                        </ol>
                                    </li>

                                    <li class="mt-5">
                                        <h3 class="text-md font-semibold mb-2"> Intellectual Property</h3>
                                        <ol class="ml-3 list-outside list-decimal space-y-3">
                                            <li>
                                                Nothing in this Terms grant you the right to use any of our intellectual property, including but not limited to, our logo, distinctive brand features, arrangement of materials, animations, commercials, videos, articles, graphics, except as may otherwise be permitted through our prior written consent
                                            </li>

                                            <li>
                                                We may request that you provide feedback on the feature and functionality of our Platform or services. You agree that any feedback you provide may be used by Sycamore for any purpose we deem fit, without any obligation or duty of confidentiality, attribution, accounting, or compensation to you.
                                            </li>

                                            <li>
                                                We will, with your consent, record and use your photographs, video, voice, or other media including derivative works (collectively, the "Images”) in connection with the exhibition, distribution, merchandising, advertising, exploiting and/or publicizing of the Images on our platform or publicly. You authorize us to edit, alter, copy, exhibit, publish, or distribute the Images for any lawful purpose. In addition, you waive any right to inspect or approve the finished product where such the Images appear. You understand and agree that SISL will be the exclusive owner of all rights to the Images in whole or in part, in any medium now known or developed hereafter, and to license others to use the Images in any manner we determine in our sole discretion, without any obligation to you.
                                            </li>
                                        </ol>
                                    </li>

                                    <li class="mt-5">
                                        <h3 class="text-md font-semibold mb-2">General Conditions</h3>
                                        <p class="md:leading-5">
                                            We may refuse access or cease access to our Platform to anyone, at any time, for any reason, and without notice. We reserve the right to make any modifications to the Platform, including terminating, changing, suspending or discontinuing any aspect of the Platform at any time, without notice. We may impose additional rules or impose limits on the use of the Platform.
                                        </p>
                                        <p class="md:leading-5 mt-3">
                                            We reserve the right to alter or modify, from time to time, the Platform’s structure or outlook or terms and conditions of use or the scope and availability of services offered by us. You agree to review the Terms regularly. Your continued access or use of our Platform will mean that you agree to any changes. You agree that we will not be liable to you or any third party for any modification, suspension or discontinuance of our Platform or for any service, content, feature or product offered through our Platform. Before you continue, you should print or save a copy of this Agreement for your records.
                                        </p>
                                    </li>

                                    <li class="mt-5">
                                        <h3 class="text-md font-semibold mb-2">Information Sharing with Third Parties</h3>
                                        <p class="md:leading-5">
                                            By using our platform and services, you agree that we may share your personal information, including your transaction and information with third parties, such as law enforcement agencies, business partners and our investors (e.g., EFCC, Verifyme,  and our investing partners).
                                        </p>
                                    </li>

                                    <li class="mt-5">
                                        <h3 class="text-md font-semibold mb-2">Default</h3>
                                        <p class="md:leading-5">
                                            A default happens when you don't send your contribution as at when due, where your contributions are manually done. A default in contribution will only mean that your interest will be calculated based on the money contributed and not on the total expected sum.  A default in information is where the customers' profile is not verifiable or truly stated. 
                                        </p>
                                    </li>

                                    <li class="mt-5">
                                        <h3 class="text-md font-semibold mb-2">Collection and Recovery</h3>
                                        <p class="md:leading-5">
                                            In applicable cases, you authorize SISL to send you payment reminders, via any medium, from time to time.  Sycamore may—with or without notice to you, and without prejudice to any other present or future right—combine or set of funds with SISL. 
                                        </p>
                                    </li>

                                    <li class="mt-5">
                                        <h3 class="text-md font-semibold mb-2">Data Protection</h3>
                                        <p class="md:leading-5">
                                            Through your use of our Platform or services, you authorize us to collect, store, process, use & protect your personal and financial data in Nigeria and any other country where we may operate which is in accordance with our Privacy Notice and subject to the National Information Technology Development Agency Act 2007(the “Act”), Nigeria Data Protection Regulation 2019 (the “Regulations”) & other applicable data protection laws.
                                        </p>
                                    </li>

                                    <li class="mt-5">
                                        <h3 class="text-md font-semibold mb-2">Errors, Inaccuracies, and Omissions</h3>
                                        <p class="md:leading-5">
                                            We reserve the right to correct any errors, inaccuracies, or omissions on our Platform, and to change or update such information at any time, without prior notice. Such errors, inaccuracies or omissions may relate to product description, pricing, promotion, and availability, and we reserve the right to cancel or refuse the provision of any service or product due to incorrect pricing or unavailability, to the extent permitted by law.
                                        </p>
                                    </li>

                                    <li class="mt-5">
                                        <h3 class="text-md font-semibold mb-2">Limitation of Liability</h3>
                                        <p class="md:leading-5">
                                            We will not be liable for any damage of any kind related to the use of our Platform. In no event will we, or our affiliates, our or their respective content or service providers, or any of our or their respective directors, officers, agents, contractors, suppliers or employees be liable to you for any direct, indirect, special, incidental, consequential, exemplary or punitive damages, losses or causes of action, or lost revenue, lost profits, lost business or sales, or any other type of damage, whether based in contract or tort (including negligence), strict liability or otherwise, arising from your use of, or the inability to use, or the performance of, our Platform or the content or material or functionality through our Platform.
                                        </p>
                                        <p class="mt-3">
                                            However, where Sycamore agrees with or may be liable in certain jurisdiction or circumstance, it will not be above the actual amount in question, or the initial payment made to the customer. Sycamore will not be liable to pay any extra damages as compensatory, consequential, or punitive, in that respect.
                                        </p>
                                    </li>

                                    <li class="mt-5">
                                        <h3 class="text-md font-semibold mb-2">Indemnification</h3>
                                        <p class="md:leading-5">
                                            You agree to defend and indemnify us, and hold us and our affiliates harmless, and our and their respective directors, officers, agents, contractors, and employees against any losses, liabilities, claims, expenses (including legal fees) in any way arising from, related to or in connection with your use of our Platform and services, your violation of the Terms, or the posting or transmission of any materials on or through the Platform by you, including but not limited to, any third party claim that any information or materials provided by you infringe upon any third party's proprietary rights.
                                        </p>
                                    </li>

                                    <li class="mt-5">
                                        <h3 class="text-md font-semibold mb-2">Amendment, Revision, or Modification</h3>
                                        <p class="md:leading-5">
                                            SISL may, in its sole discretion, modify or revise these Terms at any time, effective immediately upon notice to you either by email or by posting on the Platform (which shall be deemed constructive notice). Your continued use of the Platform following notice of any modification shall be conclusively deemed an acceptance of all such modification(s). 
                                        </p>
                                    </li>

                                    <li class="mt-5">
                                        <h3 class="text-md font-semibold mb-2">Applicable Law and Jurisdiction</h3>
                                        <p class="md:leading-5">
                                            This Terms shall be interpreted and governed by the laws in force in the Federal Republic of Nigeria. All proceedings shall be held in Nigeria and all parties agree to submit to this jurisdiction and only this jurisdiction.
                                        </p>
                                    </li>
                                </ol>

                                <div class="text-xs lg:text-sm mt-5">
                                    <p class="md:leading-5">
                                        Sycamore shall make every effort to settle all disputes amicably between its customers within a six (6) weeks' timeline, after which it will be referred to an alternative dispute resolution house in Nigeria, pursuant to the Arbitration and Conciliation Act Cap Laws of the Federation of Nigeria 2004. Sycamore's failure to exercise any right is deemed as a waiver of such right or provision.
                                    </p>

                                    <p class="mt-3 md:leading-6">
                                        All parties hereby consent to this agreement and are hereby bound by the Terms and Conditions above.
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div class="flex flex-col md:flex-row md:justify-between md:items-end pb-12">
                            <div>
                                <p class="pb-1" :class="this.customerData.contract_status != 'generated' ? 'pb-6' : ''">
                                    Thank you. <br /> For Sycamore Integrated Solutions Limited
                                </p>
                                <p v-if="this.customerData.contract_status != 'generated'" class="pb-3">
                                    <span class="text-3xl font-Allura">
                                        {{ customerData.Customer ? customerData.Customer.name : ''}}
                                    </span>
                                    <br />
                                    <span class="text-sm">
                                        {{moment().format('MMMM Do YYYY, h:mm a')}}
                                    </span>
                                    <br />
                                    <span class="text-xs">
                                        Contract {{ customerData ? customerData.contract_status : '' }}
                                    </span>
                                </p>
                                <p>
                                    Yours faithfully, <br> For Sycamore.
                                </p>
                            </div>

                            <div
                                class="flex pt-6 pr-0 md:pr-6 space-x-3"
                                v-if="this.token && this.customerData.contract_status == 'generated'"
                            >
                                <b-button
                                    label="Accept"
                                    type="is-primary"
                                    @click="decideContributionContract('accepted')"
                                    :loading="this.decision == 'accepted' && isDecidingContract"
                                    :disabled="isDecidingContract"
                                />

                                <b-button
                                    type="is-danger"
                                    label="Reject"
                                    @click="decideContributionContract('rejected')"
                                    :loading="this.decision == 'rejected' && isDecidingContract"
                                    :disabled="isDecidingContract"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div v-if="activeTab == 1">
                <TokenInvalid />
            </div>

        </div>
    </div>  
</template>

<script>
    import Ripple from "@/components/Ripple";
    import moment from "moment";
    import TokenInvalid from "@/components/TokenInvalid";
    import {getTargetContribution, decideTargetContract} from '@/api/target';
    import {getSycamoreContacts} from '@/api/contacts';

    export default {
        name: "TargetContribution",

        components:{
            Ripple,
            TokenInvalid
        },

        data() {
            return {
                errorStatus: {},
                customerData: {},
                contacts: null,
                activeTab: 0,
                decision: "",
                token: '',

                loading: false,
                isDecidingContract: false,
            }
        },

        methods: {
            async getContribution() {
                const {offer_link} = this.$route.params
                try {
                    this.loading = true
                    const response = await getTargetContribution(offer_link)
                    if(response.data.success && response.status === 200) {
                        this.customerData = response.data.data
                        this.loading = false
                    }
                    this.loading = false
                } catch (error) {
                    this.loading = false
                    this.activeTab = 1
                }
            },

            async decideContributionContract (status) {
                this.decision = status
                try {
                    this.isDecidingContract = true
                    const response = await decideTargetContract({ 
                        contribution_id: this.customerData.id, 
                        status
                    }, this.token)
                    if(response.data.success) {
                        this.isDecidingContract = false
                        this.$toast.success(
                            response.data.message,{
                            timeout: 5000
                        });
                        this.getContribution()
                    }
                } catch (error) {
                    this.isDecidingContract = false
                    this.$toast.error(
                        error.response.data.message,
                    {
                        timeout: 5000
                    });
                }
            },

		    tenure(type) {
                switch (type) {
                    case 'weekly':
                        return 'weeks'
				    case 'monthly':
                        return 'months'
                    case  'daily':
                        return 'days'
                    default:
                        return ''
                }
            },

            moment: function (date) {
                return moment(date);
            },

            async getContacts(){
                const response = await getSycamoreContacts();
                this.contacts = response.data.contact;
            }
        },

        mounted() {
            this.getContribution();
            this.getContacts(); 
        },

        created() {
            this.token = this.$route.query.token;
        }
    }
</script>
