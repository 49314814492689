<template>
    <div class="p-6">
        <div class="mb-4">
            <UpdateStaff @clicked="onClickChild" :details="staffDetails" />
        </div>
    </div>
</template>

<script>
import UpdateStaff from '@/components/UpdateStaff'

    export default {
  components: { 
      UpdateStaff,
      },
        data() {
            return {
            }
        },

        props: {
            staffDetails:{
                type:Object
            }
        }
    }
</script>
