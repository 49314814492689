import { NotificationProgrammatic as Notification } from 'buefy'
export const errorNotification = 
    function error(message) {
        Notification.open({
            duration: 3000,
            message: message ? message : 'something happened',
            position: 'is-bottom-right',
            type: 'is-danger',
        })
    }

export const successNotification = 
    function success(message) {
        Notification.open({
            duration: 3000,
            message: message ? message : 'something happened',
            position: 'is-top-right',
            type: 'is-primary',
        })
    }
