import axios from "axios";
import sycamoreServiceClient from ".";

export const verifyCMMSToken = async payload => {
    const response = await sycamoreServiceClient({
        url: `/auth/verify_token_validity`, 
        method: 'post',
        data: payload
    });

    return {
        status: response.status,
        data: response.data,
    };
};

export const uploadCMMSSignature = async (payload, token) => {
    const response = await sycamoreServiceClient({
        url: `/customer/dd/mandate/lendsqr/signature`, 
        method: 'post',
        data: payload,
        headers: {
            Authorization: token,
        },
    });

    return {
        status: response.status,
        data: response.data,
    };
};

export const updateCustomerDetails = async (payload, token) => {
    const response = await sycamoreServiceClient({
        url: `/customer/dd/mandate/mono/customer/update`, 
        method: 'post',
        data: payload,
        headers: {
            Authorization: token,
        },
    });

    return {
        status: response.status,
        data: response.data,
    };
};

export const getBanks = async (token) => {
    const response = await sycamoreServiceClient({
        url: `/customer/dd/mandate/lendsqr/banks`, 
        method: 'get',
        headers: {
            Authorization: token
        },
    });
  
    return {
      data: response.data.data,
    };

};

export const createCMMSMandate = async (payload, token) => {
    const response = await sycamoreServiceClient({
        url: `/customer/dd/mandate/lendsqr/create`, 
        method: 'post',
        data: payload,
        headers: {
            Authorization: token
        },
    });
    
    return{
      status: response.status,
      data: response.data,
    };
};

export const createMonoMandate = async (payload, token) => {
    const response = await sycamoreServiceClient({
        url: `/customer/dd/mandate/mono/create`, 
        method: 'post',
        data: payload,
        headers: {
            Authorization: token
        },
    });
    
    return{
      status: response.status,
      data: response.data,
    };
};

export const resolveBank = async (payload, token) => {
    const response = await sycamoreServiceClient({
        url: `/customer/accounts/resolve/misc?token=${token}`, 
        method: 'post',
        data: payload,
        headers: {
            Authorization: token
        }
    });
    
    return{
      data: response.data,
    }
};

export const fetchMandate = async (payload, token) => {
    const response = await sycamoreServiceClient({
        url: `customer/dd/mandate/lendsqr/letter`, 
        method: 'post',
        data: payload,
        responseType: 'arraybuffer',
        headers:  {
            'Authorization': token
        }
    })
    
    return{
        status: response.status,
        data: response.data,
    }
};

