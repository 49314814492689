<template>
    <div>
        <div class="columns is-multiline">
            <div class="column is-12 my-12 px-12">
                <b-table
                    :data="staffs"
                    :paginated="true"
                    :per-page="10"
                    :pagination-rounded="true"
                    :bordered="false"
                    :striped="true"
                    :hoverable="true"
                    detailed
                    detail-key="id"
                    detail-transition="fade"
                >
                    <b-table-column field="id" label="id" v-slot="props">
                        {{ props.row.id}}
                    </b-table-column>

                    <b-table-column field="name" width="250" label="name" v-slot="props">
                        {{ props.row.name }}
                    </b-table-column>

                    <b-table-column field="status" width="250" label="status" v-slot="props">
                        {{ props.row.status }}
                    </b-table-column>
                    
                    <b-table-column field="button" label="Button" v-slot="props">
                        <p class="control">
                            <b-tooltip label="Open detail"
                                type="is-primary is-light"
                            >
                                <button
                                    @click="toggleModals(props.row)"
                                    class="button is-small"
                                >
                                    <span class="icon is-small">
                                        <i class="ri-list-settings-line ri-lg"></i>
                                    </span>
                                </button>
                            </b-tooltip>
                        </p>
                    </b-table-column>
                
                    <template #detail="props">
                        <div class="card border-2 rounded-lg">
                            <div class="card-content">
                                <ul class="">
                                    <li class="flex justify-between text-xs pb-2 mb-2 border-b-2 text-gray-500">
                                        <span class="text-black">id</span>
                                        <span>{{ props.row.id || 'None' }}</span>
                                    </li>

                                    <li class="flex justify-between text-xs pb-2 mb-2 border-b-2 text-gray-500">
                                        <span class="text-black">user_id</span>
                                        <span>{{ props.row.user_id || 'None' }}</span>
                                    </li>

                                    <li class="flex justify-between text-xs pb-2 mb-2 border-b-2 text-gray-500">
                                        <span class="text-black">name</span>
                                        <span>{{ props.row.name || 'None' }}</span>
                                    </li>

                                    <li class="flex justify-between text-xs pb-2 mb-2 border-b-2 text-gray-500">
                                        <span class="text-black">phone</span>
                                        <span>{{ props.row.phone || 'None' }}</span>
                                    </li>
                                    
                                    <li class="flex justify-between text-xs pb-2 mb-2 border-b-2 text-gray-500">
                                        <span class="text-black">picture</span>
                                        <span>{{ props.row.picture  || 'None' }}</span>
                                    </li>
                                    
                                    <li class="flex justify-between text-xs pb-2 mb-2 border-b-2 text-gray-500">
                                        <span class="text-black">dob</span>
                                        <span>{{ props.row.dob || 'None' }}</span>
                                    </li>
                                    
                                    <li class="flex justify-between text-xs pb-2 mb-2 border-b-2 text-gray-500">
                                        <span class="text-black">gendere</span>
                                        <span>{{ props.row.gender || 'None' }}</span>
                                    </li>
                                    
                                    <li class="flex justify-between text-xs pb-2 mb-2 border-b-2 text-gray-500">
                                        <span class="text-black">address</span>
                                        <span>{{ props.row.address || 'None' }}</span>
                                    </li>
                                    
                                    <li class="flex justify-between text-xs pb-2 mb-2 border-b-2 text-gray-500">
                                        <span class="text-black">State</span>
                                        <span>{{ props.row.state || 'None' }}</span>
                                    </li>

                                    <li class="flex justify-between text-xs pb-2 mb-2 border-b-2 text-gray-500">
                                        <span class="text-black">country</span>
                                        <span>{{ props.row.country || 'None' }}</span>
                                    </li>
                                    
                                    <li class="flex justify-between text-xs pb-2 mb-2 border-b-2 text-gray-500">
                                        <span class="text-black">designation</span>
                                        <span>{{ props.row.designation || 'None' }}</span>
                                    </li>
                                    
                                    <li class="flex justify-between text-xs pb-2 mb-2 border-b-2 text-gray-500">
                                        <span class="text-black">role</span>
                                        <span>{{ props.row.role || 'None' }}</span>
                                    </li>
                                    
                                    <li class="flex justify-between text-xs pb-2 mb-2 border-b-2 text-gray-500">
                                        <span class="text-black">status</span>
                                        <span>{{ props.row.status || 'None' }}</span>
                                    </li>
                                    
                                    <li class="flex justify-between text-xs pb-2 mb-2 border-b-2 text-gray-500">
                                        <span class="text-black">transaction_pin</span>
                                        <span>{{ props.row.transaction_pin || 'None' }}</span>
                                    </li>

                                    <li class="flex justify-between text-xs pb-2 mb-2 border-b-2 text-gray-500">
                                        <span class="text-black">createdAt </span>
                                        <span>{{ new Date(props.row.createdAt).toLocaleDateString() || 'None' }}</span>
                                    </li>

                                    <li class="flex justify-between text-xs pb-2 mb-2 border-b-2 text-gray-500">
                                        <span class="text-black">updatedAt </span>
                                        <span>{{ new Date(props.row.updatedAt).toLocaleDateString() || 'None' }}</span>
                                    </li>
                                </ul>

                            </div>
                        </div>
                    </template>
                </b-table>
            </div>
        </div>

        <b-sidebar
            type="is-light"
            :fullheight="fullheight"
            :overlay="overlay"
            :right="right"
            :can-cancel="cancancel"
            v-model="open"
        >
            <div class="tw-p-4">
                <button
                    @click="open = false"
                    class="delete is-large has-background-danger"
                >
                </button>

                <SidePanelStaff  @clicked="onClickChild" :staffDetails="staffDetails" />
            </div>
        </b-sidebar>
    </div>
</template>

<script>
import axios from 'axios'
import SidePanelStaff from '@/components/SidePanelStaff'

    export default {
        components:{
            SidePanelStaff,
        },
        data() {
            return {
                isCardModalActive: false,
                component: '',
                staffs: '',
                staffDetails: '',
                open: false,
                overlay: false,
                fullheight: true,
                fullwidth: true,
                right: true,
                cancancel: ['escape'],
                isLoading: false,
            }
        },

        computed:{
        },

        methods:{
            cardModal() {
                this.$buefy.modal.open({
                    parent: this,
                    component: ModalForm,
                    hasModalCard: true,
                    customClass: 'custom-class custom-class-2',
                    trapFocus: true
                })
            },

            openModal(prop, modal){
                this.isCardModalActive= true
                this.currentData=prop
                this.component=modal
            },

            toggleModals(details) {
                this.staffDetails = details
                this.open = true;
            },

            getAllStaff(){
                axios.get(process.env.VUE_APP_ROOT_API+`/staff`)
                .then(response => {
                    this.staffs = response.data.data
                })
                .catch(e => {
                    this.errors.push(e)
                })
            },
            
        },

        created() {
            this.getAllStaff()
            
        }
    }
</script>