import { NotificationProgrammatic as Notification } from 'buefy'
    function NotificationMessate(message) {
        Notification.open({
            duration: 3000,
            message: message ? message : 'something happened',
            position: 'is-bottom-right',
            type: 'is-danger',
        })
    }
    function required(params) {
        if (!params.data && params.message) {
            NotificationMessate(params.message)
            return false;
        }

        if (!params.data && !params.message) {
            NotificationMessate(`${params.name} is required`)
           
            return false;
        }
        
        return true;
    }

    function string(params) {
        if (params.data && typeof params.data !== 'string' && params.message) {
            
            NotificationMessate(params.message)
            return false;
        }

        if (params.data && typeof params.data !== 'string' && !params.message) {
            NotificationMessate(`${params.name} must be a string`)
            return false;
        }

        return true;
    }

    function fullname(params) {
        let format = /[a-zA-Z]+\s+[a-zA-Z]+/g

        if (params.data && !(format.test(params.data)) && params.message) {
            
            NotificationMessate(params.message)
            return false;
        }

        if (params.data && !(format.test(params.data)) && !params.message) {
            NotificationMessate(`${params.name} must be firstname and lastname at least`)
            return false;
        }

        return true;
    }

    function digit(params) {
        let format = /^\d+$/

        if (params.data && !(format.test(params.data)) && params.message) {
            
            NotificationMessate(params.message)
            return false;
        }

        if (params.data && !(format.test(params.data)) && !params.message) {
            NotificationMessate(`${params.name} must be digit`);
            return false;
        }

        return true;
    }

    function number(params) {
        if (params.data && isNaN(params.data) && params.message) {
            
            NotificationMessate(params.message)
            return false;
        }

        if (params.data && isNaN(params.data) && !params.message) {
            NotificationMessate(`${params.name} must be a number`);
            return false;
        }

        return true;
    }

    function email(params) {
        let format = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

        if (params.data && !(format.test(params.data)) && params.message) {
            
            NotificationMessate(params.message)
            return true;
        }

        if (params.data && !(format.test(params.data)) && !params.message) {
            NotificationMessate(`${params.name} must be a valid email`);
            return true;
        }

        return false;
    }

    function phone(params) {
        if (params.data && (isNaN(params.data) || params.data.length !== 11) && params.message) {
            
            NotificationMessate(params.message)
            return false;
        }

        if (params.data && (isNaN(params.data) || params.data.length !== 11) && !params.message) {
            NotificationMessate(`${params.name} must be a valid phone number`);
            return false;
        }

        return true;
    }

    function phone10(params) {
        if (params.data && (isNaN(params.data) || params.data.length !== 10) && params.message) {
            
            NotificationMessate(params.message)
            return false;
        }

        if (params.data && (isNaN(params.data) || params.data.length !== 10) && !params.message) {
            NotificationMessate(`${params.name} must be a valid phone number of 10 digit`);
            return false;
        }

        return true;
    }

    function password(params) {
        let format = /^(?=.*[a-zA-Z])(?=.*[0-9])/

        if (params.data && (!(format.test(params.data)) || params.data.length < 8) && params.message) {
            
            NotificationMessate(params.message)
            return true;
        }

        if (params.data && (!(format.test(params.data)) || params.data.length < 8) && !params.message) {
            NotificationMessate(`${params.name} must be a valid password with letters, numbers and special characters`);
            return true;
        }

        return false;
    }

    function minLength(params) {
        if (params.data && (params.data.length < params.minLength) && params.message) {
            
            NotificationMessate(params.message)
            return false;
        }

        if (params.data && (params.data.length < params.minLength) && !params.message) {
            NotificationMessate(`${params.name} must be a minimum length of ${params.minLength}`);
            return false;
        }

        return true;
    }

    function min(params) {
        if (params.data && (params.data < params.min) && params.message) {
            
            NotificationMessate(params.message)
            return false;
        }

        if (params.data && (params.data < params.min) && !params.message) {
            NotificationMessate(`${params.name} must be a minimum of ${params.min}`);
            return false;
        }

        return true;
    }

    function maxLength(params) {
        if (params.data && (params.data.length > params.maxLength) && params.message) {
            
            NotificationMessate(params.message)
            return false;
        }

        if (params.data && (params.data.length > params.maxLength) && !params.message) {
            NotificationMessate(`${params.name} must be a maximum length of ${params.maxLength}`);
            return false;
        }

        return true;
    }

    function max(params) {
        if (params.data && (params.data > params.max) && params.message) {
            
            NotificationMessate(params.message)
            return false;
        }

        if (params.data && (params.data > params.max) && !params.message) {
            NotificationMessate(`${params.name} must be a maximum of ${params.max}`);
            return false;
        }

        return true;
    }

    function equalTo(params) {
        if (params.data && (params.data.length !== params.equalTo) && params.message) {
            
            NotificationMessate(params.message)
            return false;
        }

        if (params.data && (params.data.length !== params.equalTo) && !params.message) {
            NotificationMessate(`${params.name} must be equal to ${params.equalTo}`);
            return false;
        }

        return true;
    }


    function switchStatement(type, params) {
        switch (type) {
            case "required":
                return required(params);

            case "string":
                return string(params);

            case "fullname":
                return fullname(params);

            case "digit":
                return digit(params);

            case "number":
                return number(params);
        
            case "email":
                return email(params);

            case "phone":
                return phone(params);

            case "phone10":
                return phone10(params);

            case "password":
                return password(params);

            case "minLength":
                return minLength(params);

            case "min":
                return min(params);

            case "maxLength":
                return maxLength(params);
            
            case "max":
                return max(params);

            case "equalTo":
                return equalTo(params);

            default:
                return false;
        }
    }

    function processObject(params) {
        if (typeof params.type === "string") {
            let types = params.type.split("|");

            return types.every(type => {
                return switchStatement(type, params);
            });

        } else if (Array.isArray(params.type)) {
            return params.type.every(type => {
                return switchStatement(type, params);
            });
        }

        return false;
    }

    export const validator = function validate(params) {
        if (Array.isArray(params)) {
            if (params.length > 0) {
                let results = params.map(processObject);
                return results.every(result => result);
                
            } else {
                NotificationMessate("The validator array is empty, please pass an object.");
                return false;
            }

        } else {
            NotificationMessate("The validator is empty, please pass some data.");
            return false;
        }
    }
    export const onFileChange = (value, callback ) => {
        const allowedExtensions = ['jpg', 'jpeg', 'png'];

        if (value) {
            const fileExtension = value.type && value.type.split('/')[1].toLowerCase();
            if (allowedExtensions.includes(fileExtension)) {
                callback && callback()
                return true
            } else {
                    Notification.open({
                    duration: 4000,
                    message: 'Only image files (jpg, jpeg, png) are allowed.',
                    type: 'is-danger',
                })
            }
        }
    }
