<template>
    <div>
        <div class="columns is-multiline">
            <div class="column is-12 my-12 px-12">
                <b-table
                    :data="loans"
                    :paginated="true"
                    :per-page="10"
                    :pagination-rounded="true"
                    :bordered="false"
                    :striped="true"
                    :hoverable="true"
                    detailed
                    detail-key="id"
                    detail-transition="fade"
                >
                    <b-table-column field="name" label="Name" v-slot="props">
                        {{ props.row.Customer.name}}
                    </b-table-column>

                    <b-table-column field="amount_disbursed" width="250" label="Amount Disbursed" v-slot="props">
                        {{ props.row.amount_disbursed }}
                    </b-table-column>

                    <b-table-column field="status" width="250" label="Loan status" v-slot="props">
                        {{ props.row.status }}
                    </b-table-column>
                    
                    <b-table-column field="button" label="Button" v-slot="props">
                        <p class="control">
                            <b-tooltip label="Open detail"
                                type="is-primary is-light"
                            >
                                <button
                                    @click="toggleModals(props.row)"
                                    class="button is-small"
                                >
                                    <span class="icon is-small">
                                        <i class="ri-list-settings-line ri-lg"></i>
                                    </span>
                                </button>
                            </b-tooltip>
                        </p>
                    </b-table-column>
                    
                    <template #detail="props">
                        <div class="card border-2 rounded-lg">
                            <div class="card-content">
                                <ul class="">
                                    <li class="flex justify-between text-xs pb-2 mb-2 border-b-2 text-gray-500">
                                        <span class="text-black">id</span>
                                        <span>{{ props.row.id || 'None' }}</span>
                                    </li>

                                    <li class="flex justify-between text-xs pb-2 mb-2 border-b-2 text-gray-500">
                                        <span class="text-black">user id</span>
                                        <span>{{ props.row.user_id || 'None' }}</span>
                                    </li>

                                    <li class="flex justify-between text-xs pb-2 mb-2 border-b-2 text-gray-500">
                                        <span class="text-black">loan id</span>
                                        <span>{{ props.row.loan_id || 'None' }}</span>
                                    </li>

                                    <li class="flex justify-between text-xs pb-2 mb-2 border-b-2 text-gray-500">
                                        <span class="text-black">investment id</span>
                                        <span>{{ props.row.amount_requested || 'None' }}</span>
                                    </li>

                                    <li class="flex justify-between text-xs pb-2 mb-2 border-b-2 text-gray-500">
                                        <span class="text-black">amount_disbursed</span>
                                        <span>{{ props.row.amount_disbursed || 'None' }}</span>
                                    </li>
                                    <li class="flex justify-between text-xs pb-2 mb-2 border-b-2 text-gray-500">
                                        <span class="text-black">amount paid</span>
                                        <span>{{ props.row.amount_paid  || 'None' }}</span>
                                    </li>
                                    
                                    <li class="flex justify-between text-xs pb-2 mb-2 border-b-2 text-gray-500">
                                        <span class="text-black">total amount</span>
                                        <span>{{ props.row.total_amount || 'None' }}</span>
                                    </li>
                                    
                                    <li class="flex justify-between text-xs pb-2 mb-2 border-b-2 text-gray-500">
                                        <span class="text-black"> rate</span>
                                        <span>{{ props.row.rate || 'None' }}</span>
                                    </li>
                                    
                                    <li class="flex justify-between text-xs pb-2 mb-2 border-b-2 text-gray-500">
                                        <span class="text-black">percentage_funded</span>
                                        <span>{{ props.row.percentage_funded || 'None' }}</span>
                                    </li>
                                    
                                    <li class="flex justify-between text-xs pb-2 mb-2 border-b-2 text-gray-500">
                                        <span class="text-black">duration</span>
                                        <span>{{ props.row.duration || 'None' }}</span>
                                    </li>

                                    <li class="flex justify-between text-xs pb-2 mb-2 border-b-2 text-gray-500">
                                        <span class="text-black">duration_type</span>
                                        <span>{{ props.row.duration_type || 'None' }}</span>
                                    </li>

                                    <li class="flex justify-between text-xs pb-2 mb-2 border-b-2 text-gray-500">
                                        <span class="text-black">loan_type </span>
                                        <span>{{ props.row.loan_type || 'None' }}</span>
                                    </li>
                                    <li class="flex justify-between text-xs pb-2 mb-2 border-b-2 text-gray-500">
                                        <span class="text-black">repayment default</span>
                                        <span>{{ props.row.repayment_default || 'None' }}</span>
                                    </li>
                                    
                                    <li class="flex justify-between text-xs pb-2 mb-2 border-b-2 text-gray-500">
                                        <span class="text-black">contract status</span>
                                        <span>{{ props.row.contract_status || 'None' }}</span>
                                    </li>
                                    
                                    <li class="flex justify-between text-xs pb-2 mb-2 border-b-2 text-gray-500">
                                        <span class="text-black">reason</span>
                                        <span>{{ props.row.reason || 'None' }}</span>
                                    </li>
                                    
                                    <li class="flex justify-between text-xs pb-2 mb-2 border-b-2 text-gray-500">
                                        <span class="text-black">offer link</span>
                                        <span>{{ props.row.offer_link || 'None' }}</span>
                                    </li>

                                    <li class="flex justify-between text-xs pb-2 mb-2 border-b-2 text-gray-500">
                                        <span class="text-black">loan officer</span>
                                        <span>{{ props.row.loan_officer || 'None' }}</span>
                                    </li>

                                    <li class="flex justify-between text-xs pb-2 mb-2 border-b-2 text-gray-500">
                                        <span class="text-black">assigned by</span>
                                        <span>{{ props.row.assigned_by || 'None' }}</span>
                                    </li>

                                    <li class="flex justify-between text-xs pb-2 mb-2 border-b-2 text-gray-500">
                                        <span class="text-black">risk officer</span>
                                        <span>{{ props.row.risk_officer || 'None' }}</span>
                                    </li>

                                    <li class="flex justify-between text-xs pb-2 mb-2 border-b-2 text-gray-500">
                                        <span class="text-black">risk_assigned_by</span>
                                        <span>{{ props.row.risk_assigned_by || 'None' }}</span>
                                    </li>

                                    <li class="flex justify-between text-xs pb-2 mb-2 border-b-2 text-gray-500">
                                        <span class="text-black">disbursement_date</span>
                                        <span>{{ props.row.disbursement_date || 'None' }}</span>
                                    </li>

                                    <li class="flex justify-between text-xs pb-2 mb-2 border-b-2 text-gray-500">
                                        <span class="text-black">next_repayment_date</span>
                                        <span>{{ props.row.next_repayment_date || 'None' }}</span>
                                    </li>

                                    <li class="flex justify-between text-xs pb-2 mb-2 border-b-2 text-gray-500">
                                        <span class="text-black">last_repayment_date</span>
                                        <span>{{ props.row.last_repayment_date || 'None' }}</span>
                                    </li>

                                    <li class="flex justify-between text-xs pb-2 mb-2 border-b-2 text-gray-500">
                                        <span class="text-black">created_by_staff</span>
                                        <span>{{ props.row.created_by_staff || 'None' }}</span>
                                    </li>
                                    <li class="flex justify-between text-xs pb-2 mb-2 border-b-2 text-gray-500">
                                        <span class="text-black">created_by_agent</span>
                                        <span>{{ props.row.created_by_agent || 'None' }}</span>
                                    </li>
                                    <li class="flex justify-between text-xs pb-2 mb-2 border-b-2 text-gray-500">
                                        <span class="text-black">updated_by_staff</span>
                                        <span>{{ props.row.updated_by_staff || 'None' }}</span>
                                    </li>
                                    <li class="flex justify-between text-xs pb-2 mb-2 border-b-2 text-gray-500">
                                        <span class="text-black">last_reminder_date</span>
                                        <span>{{ props.row.last_reminder_date || 'None' }}</span>
                                    </li>
                                    <li class="flex justify-between text-xs pb-2 mb-2 border-b-2 text-gray-500">
                                        <span class="text-black">product_type_id</span>
                                        <span>{{ props.row.product_type_id || 'None' }}</span>
                                    </li>
                                    <li class="flex justify-between text-xs pb-2 mb-2 border-b-2 text-gray-500">
                                        <span class="text-black">last_status</span>
                                        <span>{{ props.row.last_status || 'None' }}</span>
                                    </li>
                                    <li class="flex justify-between text-xs pb-2 mb-2 border-b-2 text-gray-500">
                                        <span class="text-black">parent_loan_id</span>
                                        <span>{{ props.row.parent_loan_id || 'None' }}</span>
                                    </li>
                                    <li class="flex justify-between text-xs pb-2 mb-2 border-b-2 text-gray-500">
                                        <span class="text-black">status</span>
                                        <span>{{ props.row.status || 'None' }}</span>
                                    </li>
                                    <li class="flex justify-between text-xs pb-2 mb-2 border-b-2 text-gray-500">
                                        <span class="text-black">credit_clan_id</span>
                                        <span>{{ props.row.credit_clan_id || 'None' }}</span>
                                    </li>
                                    <li class="flex justify-between text-xs pb-2 mb-2 border-b-2 text-gray-500">
                                        <span class="text-black">createdAt</span>
                                        <span>{{ props.row.createdAt || 'None' }}</span>
                                    </li>
                                    <li class="flex justify-between text-xs pb-2 mb-2 border-b-2 text-gray-500">
                                        <span class="text-black">updatedAt</span>
                                        <span>{{ props.row.updatedAt || 'None' }}</span>
                                    </li>

                                </ul>

                            </div>
                        </div>
                    </template>
                </b-table>
            </div>
        </div>

        <b-sidebar
            type="is-light"
            :fullheight="fullheight"
            :overlay="overlay"
            :right="right"
            :can-cancel="cancancel"
            v-model="open"
        >
            <div class="tw-p-4">
                <button
                    @click="open = false"
                    class="delete is-large has-background-danger"
                >
                </button>

                <SidePanelLoans  @clicked="onClickChild" :staffs="staffs" :loanDetails="loanDetails" />
            </div>
        </b-sidebar>
    </div>
</template>

<script>
import axios from 'axios'
import SidePanelLoans from '@/components/SidePanelLoans'

    export default {
        components:{
            SidePanelLoans,
        },
        data() {
            return {
                MIGRATION_URL: process.env.VUE_APP_MIGRATION_URL,
                isCardModalActive: false,
                loans:'',
                component: '',
                loanDetails: '',
                staffs:[],

                open: false,
                overlay: false,
                fullheight: true,
                fullwidth: true,
                right: true,
                cancancel: ['escape'],
                isLoading: false,
            }
        },

        computed:{
        },

        methods:{
            cardModal() {
                this.$buefy.modal.open({
                    parent: this,
                    component: ModalForm,
                    hasModalCard: true,
                    customClass: 'custom-class custom-class-2',
                    trapFocus: true
                })
            },

            openModal(prop, modal){
                this.isCardModalActive= true
                this.currentData=prop
                this.component=modal
            },

            toggleModals(details) {
                this.loanDetails = details
                this.open = true;
            },
            getAllLoans(){
                axios.get(process.env.VUE_APP_ROOT_API+`/loans`)
                .then(response => {
                    this.loans = response.data.data
                })
                .catch(e => {
                    
                })
            },
            getAllStaff(){
                axios.get(process.env.VUE_APP_ROOT_API+`/staff`)
                .then(response => {
                    this.staffs = response.data.data
                })
                .catch(e => {
                    this.errors.push(e)
                })
            }
        },
        created() {
            this.getAllLoans()
            this.getAllStaff()
        }
    }
</script>