<template>
    <div>
    <div class="card">
    <div class="card-content">
        <div class="pb-6">
            <p class="text-center font-semibold has-text-primary text-xl">Update Customer's Info</p>
        </div>
        <form action=""  @submit.prevent="updateCustomer" method="PUT">
            <section class="columns is-multiline">
                <div class="column is-6">
                    <b-field label="Full name">
                        <b-input v-model="details.name"></b-input>
                    </b-field>
                </div>
                <div class="column is-6">
                    <b-field label="Email">
                        <b-input v-model="details.email"></b-input>
                    </b-field>
                </div>
                <div class="column is-4">
                    <b-field label="Phone">
                        <b-input v-model="details.phone"></b-input>
                    </b-field>
                </div>
                <div class="column is-4">
                    <b-field label="Date of Birth">
                        <b-input v-model="details.dob"></b-input>
                    </b-field>
                </div>
                
                <div class="column is-4">
                    <div class="control">
                        <div class="field">
                            <label class="label tw-text-gray-500">
                                Gender 
                            </label>
                            <div class="control">
                                <div 
                                    class="select is-fullwidth"
                                >
                                    <select name="work_designation" v-model="details.gender">
                                        <option 
                                            value="male"
                                        >
                                            male
                                        </option>
                                        <option 
                                            value="female"
                                        >
                                            female
                                        </option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="column is-4 field">
                <label class="label tw-text-gray-500">
                    Marital status 
                </label>
                <div class="control">
                    <div 
                        class="select is-fullwidth"
                    >
                        <select name="work_designation" v-model="details.marital_status">
                            <option 
                                value="single"
                            >
                                Single
                            </option>
                            <option 
                                value="married"
                            >
                                Married
                            </option>
                            <option 
                                value="widowed"
                            >
                                widowed
                            </option>
                            <option 
                                value="divorced"
                            >
                                divorced
                            </option>
                        </select>
                    </div>
                </div>
                </div>

                <div class="column is-4">
                    <b-field label="User type">
                        <b-input v-model="details.user_type"></b-input>
                    </b-field>
                </div>

                 <div class="column is-4">
                    <b-field label="tag">
                        <b-input v-model="details.tag"></b-input>
                    </b-field>
                </div>

                 <div class="column is-6">
                    <b-field label="bvn">
                        <b-input v-model="details.bvn" maxlength="11"></b-input>
                    </b-field>
                </div>

                 <div class="column is-6">
                    <b-field label="NIN">
                        <b-input v-model="details.nin" maxlength="11"></b-input>
                    </b-field>
                </div>

                <div class="column is-6">
                    <b-field label="Address">
                        <b-input v-model="details.address"></b-input>
                    </b-field>
                </div>
                <div class="column is-6">
                    <b-field label="Local Government A.">
                        <b-input v-model="details.lga"></b-input>
                    </b-field>
                </div>
                <div class="column is-6">
                    <b-field label="state">
                        <b-input v-model="details.state"></b-input>
                    </b-field>
                </div>

                <div class="column is-6">
                    <b-field label="country">
                        <b-input v-model="details.country"></b-input>
                    </b-field>
                </div>

                 <div class="column is-6">
                    <b-field label="Nature of accomodation">
                        <b-input v-model="details.nature_of_accomodation"></b-input>
                    </b-field>
                </div>

                 <div class="column is-6">
                    <b-field label="Years at residence">
                        <b-input type="number" v-model="details.years_at_residence"></b-input>
                    </b-field>
                </div>
                   
                

                <div class="column is-6">
                    <b-field label="Registration type">
                        <b-input v-model="details.registration_type"></b-input>
                    </b-field>
                </div>

                <div class="column is-6">
                    <b-field label="Alternate phone">
                        <b-input v-model="details.alt_phone" maxlength="11"></b-input>
                    </b-field>
                </div>

                <div class="column is-6">
                    <b-field label="Alternate email">
                        <b-input v-model="details.alt_email"></b-input>
                    </b-field>
                </div>

                <div class="column is-6">
                    <b-field label="User status">
                        <b-input v-model="details.user_status"></b-input>
                    </b-field>
                </div>   

                <div class="column is-6">
                    <b-field label="Group id">
                        <b-input v-model="details.group_id"></b-input>
                    </b-field>
                </div> 

                <div class="column is-6">
                    <b-field label="creditiumId">
                        <b-input v-model="details.creditiumId"></b-input>
                    </b-field>
                </div>
            </section>

            <div class="mt-6">
                <button type="submit"
                class="button is-success" expanded
                :class="isLoading ? 'is-loading' : ''"
                :disabled="isLoading ? true : false"
                >update</button>
            </div>
        </form>
    </div>
    </div>
    </div>
</template>

<script>
import axios from 'axios'
    export default {
        props:{
            details:Array
        },
        data() {
            return {
                isLoading: false,

            }
        },

        computed:{
            params(){
                let obj={name: this.details.name,
                    email: this.details.email,
                    phone: this.details.phone,
                    dob: this.details.dob,
                    gender: this.details.gender,
                    address: this.details.address,
                    lga: this.details.lga,
                    state: this.details.state,
                    tag: this.details.tag,
                    country: this.details.country,
                    nature_of_accomodation: this.details.nature_of_accomodation,
                    years_at_residence: this.details.years_at_residence,
                    marital_status: this.details.marital_status,
                    bvn: this.details.bvn,
                    nin: this.details.nin,
                    preferred_monthly_repayment_date: this.details.preferred_monthly_repayment_date,
                    preferred_weekly_repayment_date: this.details.preferred_weekly_repayment_date,
                    preferred_daily_repayment_time: this.details.preferred_daily_repayment_time,
                    creditiumId: this.details.creditiumId,
                    group_id: this.details.group_id,
                    user_status: this.details.user_status,
                    alt_phone: this.details.alt_phone,
                    alt_email: this.details.alt_email}

                    for (var propName in obj) {
                        if (obj[propName] === null || obj[propName] === undefined) {
                        delete obj[propName];
                        }
                    }
                    return obj
            }
        },

        methods:{
            updateCustomer(){
                this.isLoading = true
                axios.put(process.env.VUE_APP_ROOT_API+`/customers/${this.details.id}`, this.params)
                .then(response => {
                    this.$buefy.toast.open({
                            message: response.data.message,
                            type: 'is-success'
                        })
                    this.$emit('clicked', 'someValue')
                    // this.$router.go()
                })
                .catch(e => {
                    this.$buefy.toast.open({
                        message: "An error occured",
                        type: 'is-danger'
                    })
                })
                .finally(() => {
                        this.isLoading = false

                    })
                }
  
        },
    }
</script>