import sycamoreServiceClient from ".";

export const fetchLoan = async (offer_link) => {
  const response = await sycamoreServiceClient({
    url: `/loan/offer_link/${offer_link}`,
    method: "get",
  });

  return {
    status: response.status,
    data: response.data,
  };
};

export const decideContract = async (body, token) => {
  try {
    const response = await sycamoreServiceClient({
      url: "/loan/contract/decide",
      method: "post",
      data: body,
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    });
    return Promise.resolve({
      status: response.status,
      data: response.data,
    });
  } catch (error) {
    return Promise.reject(error);
  }
};
