<template>
    <div>
        <div class="card">
            <div class="card-content">
        <div class="pb-6">
            <p class="text-center font-semibold has-text-primary text-xl">Update Investments</p>
        </div>
        <form action="" @submit.prevent="updateInvestments" method="PUT">
            <section class="columns is-multiline">
                <div class="column is-6">
                    <b-field label="Principal amount">
                        <b-input v-model="details[0].principal_amount"></b-input>
                    </b-field>
                </div>
                <div class="column is-6">
                    <b-field label="Tenure">
                        <b-input v-model="details[0].tenure"></b-input>
                    </b-field>
                </div>
                <div class="column is-12">
                    <b-field label="Tenure type">
                        <b-input v-model="details[0].tenure_type"></b-input>
                    </b-field>
                </div>
                <div class="column is-6">
                    <b-field label="Interest rate">
                        <b-input v-model="details[0].interest_rate"></b-input>
                    </b-field>
                </div>
                
                <div class="column is-6">
                    <b-field label="Start date">
                        <b-input v-model="details[0].start_date"></b-input>
                    </b-field>
                </div>

                <div class="column is-6">
                    <b-field label="End date">
                        <b-input v-model="details[0].end_date"></b-input>
                    </b-field>
                </div>

                <div class="column is-6">
                    <b-field label="Maturity date">
                        <b-input v-model="details[0].maturity_date"></b-input>
                    </b-field>
                </div>

                <!-- <div class="column is-6">
                    <b-field label="Product type id">
                        <b-input v-model="details[0].product_type_id"></b-input>
                    </b-field>
                </div> -->

                <div class="column is-6">
                    <b-field label="Product id">
                    <div class="control">
                        <div 
                            class="select is-fullwidth"
                        >
                            <select name="investment_agent_id" v-model="details[0].product_type_id">
                                <option 
                                    v-for="product in products" 
                                    :key="product.id" 
                                    :value="product.id"
                                >
                                    {{product.id }}: {{ product.name }}
                                </option>
                            </select>
                        </div>
                    </div>
                    </b-field>

                </div>

                <div class="column is-6">
                    <b-field label="Last accrued">
                        <b-input v-model="details[0].last_accrued"></b-input>
                    </b-field>
                </div>

                <div class="column is-6">
                    <b-field label="Total accrued">
                        <b-input v-model="details[0].total_accrued"></b-input>
                    </b-field>
                </div>

                <div class="column is-6">
                    <b-field label="Investment agent id">
                    <div class="control">
                        <div 
                            class="select is-fullwidth"
                        >
                            <select name="investment_agent_id" v-model="details[0].investment_agent_id">
                                <option 
                                    v-for="staff in staffs" 
                                    :key="staff.id" 
                                    :value="staff.id"
                                >
                                    {{staff.id }}: {{ staff.name }}
                                </option>
                            </select>
                        </div>
                    </div>
                    </b-field>

                </div>

                <div class="column is-6">
                    <b-field label="Investment parent id">
                        <b-input v-model="details[0].investment_parent_id"></b-input>
                    </b-field>
                </div>

                <div class="column is-6">
                    <b-field label="Last reminder date">
                        <b-input v-model="details[0].last_reminder_date"></b-input>
                    </b-field>
                </div>

                <div class="column is-6">
                    <b-field label="Total withdrawn">
                        <b-input v-model="details[0].total_withdrawn"></b-input>
                    </b-field>
                </div>

                <div class="column is-6">
                    <b-field label="Contract status">
                        <b-input v-model="details[0].contract_status"></b-input>
                    </b-field>
                </div>

                <div class="column is-6">
                    <b-field label="Status">
                        <b-input v-model="details[0].status"></b-input>
                    </b-field>
                </div>
            </section>
            <div class="mt-6">
                <button type="submit"
                class="button is-success"
                :class="isLoading ? 'is-loading' : ''"
                :disabled="isLoading ? true : false"
                >update</button>
            </div>
        </form>
    </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
    export default {
        props:{
            details:Array,
            staffs:Array,
            products:Array
        },
        data() {
            return {
                isLoading: false,
            }
        },

        computed:{
            params(){
                let obj={ principal_amount: this.details[0].principal_amount,
                    tenure: this.details[0].tenure,
                    interest_rate: this.details[0].interest_rate,
                    tenure_type: this.details[0].tenure_type,
                    start_date: this.details[0].start_date, 
                    end_date: this.details[0].end_date,
                    maturity_date: this.details[0].maturity_date ,
                    product_type_id: this.details[0].product_type_id,
                    last_accrued: this.details[0].last_accrued,
                    total_accrued: this.details[0].total_accrued, 
                    investment_agent_id: this.details[0].investment_agent_id, 
                    investment_parent_id: this.details[0].investment_parent_id, 
                    last_reminder_date: this.details[0].last_reminder_date ,
                    total_withdrawn: this.details[0].total_withdrawn, 
                    status: this.details[0].status}

                    for (var propName in obj) {
                        if (obj[propName] === null || obj[propName] === undefined) {
                        delete obj[propName];
                        }
                    }
                    return obj
            }
        },

        methods:{
           
            updateInvestments(){
                this.isLoading = true
                axios.put(process.env.VUE_APP_ROOT_API+`/investments/${this.details[0].id}`,this.params)
                .then(response => {
                    this.$buefy.toast.open({
                        message: response.data.message,
                        type: 'is-success'
                    })
                    this.$emit('clicked', 'someValue')
                    // this.$router.go()
                })
                .catch(e => {
                    this.errors.push(e)
                    this.$buefy.toast.open({
                        message: e.message,
                        type: 'is-danger'
                    })
                })
                .finally(() => {
                    this.isLoading = false
                })
            }
  
        },

        mounted(){
            
        }
    }
</script>