<template>
    <div class="">
        <div class="card">
            <div class="card-content">
                <div class="pb-6">
            <p class="text-center font-semibold has-text-primary text-xl">Update Account Info</p>
            <p class="has-text-primary text-center text-medium">Account with id - {{details.id}}</p>
        </div>

        <form action="" @submit.prevent="updateAccount" method="PUT">
            <section class="columns is-multiline">
                <div class="column is-6">
                    <b-field label="Account name">
                        <b-input v-model="details.account_name"></b-input>
                    </b-field>
                </div>
                <div class="column is-6">
                    <b-field label="Account Number">
                        <b-input v-model="details.account_number"></b-input>
                    </b-field>
                </div>
                <div class="column is-6">
                    <b-field label="Bank Name">
                        <b-input v-model="details.bank_name"></b-input>
                    </b-field>
                </div>
                <div class="column is-6">
                    <b-field label="Bank code">
                        <b-input v-model="details.bank_code"></b-input>
                    </b-field>
                </div>
            </section>
            <div class="mt-6">
                <button type="submit" class="button is-success"
                 :class="isLoading ? 'is-loading' : ''"
                :disabled="isLoading ? true : false">update</button>
            </div>
        </form>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
    export default {
        props:{
            details: Array,
        },
        data() {
            return {
                isLoading: false,

                accountDetails:'',
                id:0,
                account_name: '',
                account_number: '',
                bank_name: '',
                bank_code: ''

            }
        },

        computed:{
            params(){
                let obj={
                    account_name: this.details.account_name,
                    account_number: this.details.account_number,
                    bank_name: this.details.bank_name,
                    bank_code: this.details.bank_code  
                    }

                    for (var propName in obj) {
                        if (obj[propName] === null || obj[propName] === undefined) {
                        delete obj[propName];
                        }
                    }
                    return obj
            }
        },


        methods:{
           
            updateAccount(){
                this.isLoading=true
                axios.put(process.env.VUE_APP_ROOT_API+`/customers/account/${this.details.id}`,this.params)
                .then(response => {
                    this.$buefy.toast.open({
                        message: response.data.message,
                        type: 'is-success'
                    })
                    // this.$router.go()
                    this.$emit('clicked', 'someValue')
                })
                .catch(e => {
                    this.$buefy.toast.open({
                        message: response.msg,
                        type: 'is-danger'
                    })
                })
                .finally(() => {
                    this.isLoading = false
                })
                }
        },

        mounted(){

        }
    }
</script>