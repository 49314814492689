import sycamoreServiceClient from '.';

export const fetchInvestment = async offer_link => {
  const response = await sycamoreServiceClient({
    url: `/investment/contract/${offer_link}`,
    method: 'get',
  });

  return {
    status: response.status,
    data: response.data,
  };
};

export const decideContract = async body => {
  const response = await sycamoreServiceClient({
    url: '/investment/contract/decide',
    method: 'post',
    data: body,
  });

  return {
    status: response.status,
    data: response.data,
  };
};
