import Vue from 'vue'
import App from './App.vue'
import router from './router'
import Toast from "vue-toastification";
import { library } from '@fortawesome/fontawesome-svg-core'
import { faUserSecret } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { fas } from "@fortawesome/free-solid-svg-icons";
import Buefy from 'buefy';


import './index.css'
import "vue-toastification/dist/index.css";
import 'buefy/dist/buefy.css'
import 'remixicon/fonts/remixicon.css';
import './assets/css/variables.scss';


library.add(faUserSecret)
library.add(fas);
Vue.component('vue-fontawesome', FontAwesomeIcon);


Vue.config.productionTip = false
Vue.use(Toast);
Vue.use(Buefy, {
  defaultIconComponent: 'vue-fontawesome',
  defaultIconPack: 'fas',
  customIconPacks: {
      'fas': {
          sizes: {
              'is-xsmall': 'xs',
              'is-small': 'sm',
              default: '',
              'is-large': 'lg',
              'is-xlarge': '2x'
          }
      },
      'far': {
          sizes: {
              'is-xsmall': 'xs',
              'is-small': 'sm',
              default: '',
              'is-large': 'lg',
              'is-xlarge': '2x'
          }
      },
      'fab': {
          sizes: {
              'is-xsmall': 'xs',
              'is-small': 'sm',
              default: '',
              'is-large': 'lg',
              'is-xlarge': '2x'
          }
      }
  }
});
Vue.use(Buefy, {
    defaultIconComponent: "vue-fontawesome",
    defaultIconPack: "fa",
    fas: {
      sizes: {
        'default': '',
        'is-small': 'fa-xs',
        'is-medium': 'fa-lg',
        'is-large': 'fa-2x'
      },
        iconPrefix: ""
      }
  });
   
new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
