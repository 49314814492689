<template>
    <div class="p-6">
        <div class="mb-4">
            <UpdateInvestment @clicked="onClickChild" :products="products" :staffs="staffs" :details="investmentDetails"/>
        </div>

        
    </div>
</template>

<script>
import UpdateInvestment from '@/components/UpdateInvestment'

    export default {
  components: { 
      UpdateInvestment,
      },
        data() {
            return {
            }
        },

        props: {
            investmentDetails: {
                type: Array,
            },
            staffs:{
                type: Array
            },
            products:{
                type:Array
            }
        }
    }
</script>