<template>
    <div>
    <div class="card">
    <div class="card-content">
        <div class="pb-6">
            <p class="text-center font-semibold has-text-primary text-xl">Update Work Info</p>
        </div>
        <form action="" @submit.prevent="updateWorkDetails" method="PUT">
            <section class="columns is-multiline">
                <!-- COMPANY NAME -->
                <div class="column is-6">
                    <b-field label="Company name">
                        <b-input v-model="details[0].work_company"></b-input>
                    </b-field>
                </div>
                
                <!-- WORK EMAIL -->
                <div class="column is-6">
                    <b-field label="Email">
                        <b-input v-model="details[0].work_email"></b-input>
                    </b-field>
                </div>
                <!-- WORK ADDRESS  -->
                <div class="column is-12">
                    <b-field label="Address">
                        <b-input v-model="details[0].work_address"></b-input>
                    </b-field>
                </div>
                <!-- DESIGNATION -->
                <div class="column is-6">
                    <div class="control">
                        <div class="field">
                        <label class="label tw-text-gray-500">
                            Designation 
                        </label>
                        <div class="control">
                            <div 
                                class="select is-fullwidth"
                                :class="isLoadingGd ? is-loading : ''"
                            >
                                <select name="work_designation" v-model="details[0].work_designation">
                                    <option 
                                        v-for="designation_type in designation_types" 
                                        :key="designation_type.id" 
                                        :value="designation_type.id"
                                    >
                                        {{ designation_type.name }}
                                    </option>
                                </select>
                            </div>
                        </div>
                    </div>
                    </div>
                </div>
                <!-- SECTOR -->
                <div class="column is-6">
                    <div class="control">
                        <div class="field">
                        <label class="label tw-text-gray-500">
                            Sector
                        </label>
                        <div 
                            class="select is-fullwidth"
                            :class="isLoadingGs ? is-loading : ''"
                        >
                            <select name="work_sector" v-model="details[0].work_occupation">
                                <option 
                                    v-for="sector_type in sector_types" 
                                    :key="sector_type.id" 
                                    :value="sector_type.id"
                                >
                                    {{ sector_type.name }}
                                </option>
                            </select>
                        </div>
                    </div>
                    </div>
                </div>
                <!-- OCCUPATION -->
                <div class="column is-6">
                    <div class="control"><div class="field">
                        <label class="label tw-text-gray-500">
                            Occupation 
                        </label>
                        <div class="control">
                                    <div 
                                        class="select is-fullwidth" 
                                        :class="isLoadingGo ? is-loading : ''"
                                    >
                                        <select name="work_occupation" v-model="details[0].work_occupation">
                                            <option 
                                                v-for="occupation_type in occupation_types" 
                                                :key="occupation_type.id" 
                                                :value="occupation_type.id"
                                            >
                                                {{ occupation_type.name }}
                                            </option>
                                        </select>
                                    </div>
                                </div>
                    </div>
                    </div>
                </div>
                <!-- NET INCOME -->
                <div class="column is-6">
                    <b-field label="Net monthly income">
                        <b-input v-model="details[0].work_netly_income"></b-input>
                    </b-field>
                </div>
                <!-- Role -->
                <div class="column is-6">
                    <b-field label="Role">
                        <b-input v-model="details[0].work_role"></b-input>
                    </b-field>
                </div>
                <!-- START DATE -->
                 <div class="column is-6">
                            <div class="field">
                                <label class="label tw-text-gray-500">
                                    Start date
                                </label>

                                <div class="control">
                                    <input class="input" name="work_start_date" type="date" v-model="details[0].work_start_date" />
                                </div>
                            </div>
                        </div>
                        <!-- END DATE -->
                         <div class="column is-6">
                            <div class="field">
                                <label class="label tw-text-gray-500">
                                    Start date
                                </label>

                                <div class="control">
                                    <input class="input" name="work_start_date" type="date" v-model="details[0].work_start_date" />
                                </div>
                            </div>
                        </div>
            </section>
            <div class="mt-6">
                <button type="submit"
                class="button is-success"
                :class="isLoading ? 'is-loading' : ''"
                :disabled="isLoading ? true : false"
                >update</button>
            </div>
           

        </form>
    </div>
    </div>
    </div>
</template>

<script>
import axios from 'axios'
    export default {
        props:{
            currentData:Object,
            details:Array
        },
        data() {
            return {
                id:'',
                work_company: '',
                work_address: '',
                work_email: '',
                work_sector: null,
                work_netly_income: null,
                work_occupation: null,
                work_designation: null,
                work_role: '',
                work_start_date: '',
                work_end_date: '', 

                occupation_types: [],
                designation_types: [],
                sector_types:[],

                isLoading: false,
                isLoadingGo: false,
                isLoadingGs: false,
                isLoadingGd: false,
            }
        },

        computed:{
            params(){
                let obj={
                    work_company: this.details[0].work_company,
                    work_address: this.details[0].work_address,
                    work_email: this.details[0].work_email,
                    work_sector: this.details[0].work_sector,
                    work_netly_income: this.details[0].work_netly_income,
                    work_occupation: this.details[0].work_occupation,
                    work_designation: this.details[0].work_designation,
                    work_role: this.details[0].work_role,
                    work_start_date: this.details[0].work_start_date,
                    work_end_date: this.details[0].work_end_date
                    }

                    for (var propName in obj) {
                        if (obj[propName] === null || obj[propName] === undefined) {
                        delete obj[propName];
                        }
                    }
                    return obj
            }
        },

        methods:{
           
            updateWorkDetails(){
                this.isLoading = true
                axios.put(process.env.VUE_APP_ROOT_API+`/customers/work-info/${this.details[0].id}`, this.params)
                .then(response => {
                    this.$buefy.toast.open({
                        message: response.data.message,
                        type: 'is-success'
                    })
                    this.$emit('clicked', 'someValue')
                    // this.$router.go()
                })
                .catch(e => {
                
                    this.$buefy.toast.open({
                        message: response.message,
                        type: 'is-danger'
                    })
                })
                .finally(() => {
                    this.isLoading = false

                })
            },
           getOccupationTypes(){
                this.isLoadingGo = true;
                axios.get(`https://sycamore-data-migration.herokuapp.com/api/v1/data/designations`)
                
                .then(({ data }) => {

                    this.occupation_types = data.data
                })

                .catch((error) => {
                    this.logErrorStatus = error;
                })

                .finally(() => {
                    this.isLoadingGo = false
                })
            },
            
            getSectorTypes(){
                this.isLoadingGs = true;
                axios.get('https://sycamore-data-migration.herokuapp.com/api/v1/data/sectors')
                
                .then(({ data }) => {
                    this.sector_types = data.data
                })

                .catch((error) => {
                    this.logErrorStatus = error;
                })

                .finally(() => {
                    this.isLoadingGs = false
                })
            },

            getDesignationTypes(){
                this.isLoadingGd = true;
                axios.get('https://sycamore-data-migration.herokuapp.com/api/v1/data/designations')
                
                .then(({ data }) => {
                    this.designation_types = data.data
                })

                .catch((error) => {
                    this.logErrorStatus = error;
                })

                .finally(() => {
                    this.isLoadingGd = false
                })
            },

  
        },

        mounted(){
            this.getSectorTypes()
            this.getOccupationTypes()
            this.getDesignationTypes()
        }
    }
</script>