<template>
    <div>
        <div class="card">
            <div class="card-content">
        <div class="pb-6">
            <p class="text-center font-semibold has-text-primary text-xl">Update Next of Kin details</p>
        </div>
        <form action="" @submit.prevent="updateNOK" method="PUT">
            <section class="columns is-multiline">
                <div class="column is-6">
                    <b-field label="Next of Kin's name">
                        <b-input v-model="details[0].nok_name"></b-input>
                    </b-field>
                </div>
                <div class="column is-6">
                    <b-field label="Email">
                        <b-input v-model="details[0].nok_email"></b-input>
                    </b-field>
                </div>
                <div class="column is-12">
                    <b-field label="Address">
                        <b-input v-model="details[0].nok_address"></b-input>
                    </b-field>
                </div>
                <div class="column is-6">
                    <b-field label="Phone">
                        <b-input v-model="details[0].nok_phone"></b-input>
                    </b-field>
                </div>
                
                <div class="column is-6">
                    <b-field label="Relationship">
                        <b-input v-model="details[0].nok_relationship"></b-input>
                    </b-field>
                </div>
            </section>
            <div class="mt-6">
                <button type="submit"
                class="button is-success"
                :class="isLoading ? 'is-loading' : ''"
                :disabled="isLoading ? true : false"
                >update</button>
            </div>
        </form>
    </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
    export default {
        props:{
            details:Array
        },
        data() {
            return {
                id:'',
                nok_name: '',
                nok_email: '',
                nok_phone: '',
                nok_address: '',
                nok_relationship: '',

                isLoading: false,
            }
        },

        computed:{
            params(){
                let obj={
                    nok_name: this.details[0].nok_name,
                    nok_email: this.details[0].nok_email,
                    nok_phone: this.details[0].nok_phone,
                    nok_address: this.details[0].nok_address,
                    nok_relationship: this.details[0].nok_relationship 
                    }

                    for (var propName in obj) {
                        if (obj[propName] === null || obj[propName] === undefined) {
                        delete obj[propName];
                        }
                    }
                    return obj
            }
        },


        methods:{
           
            updateNOK(){
                this.isLoading = true
                axios.put(process.env.VUE_APP_ROOT_API+`/customers/next-of-kin/${this.details[0].id}`, this.params)
                .then(response => {
                    this.$buefy.toast.open({
                        message: response.data.message,
                        type: 'is-success'
                    })
                    this.$emit('clicked', 'someValue')
                    // this.$router.go()
                })
                .catch(e => {
                    this.$buefy.toast.open({
                        message: e.message,
                        type: 'is-danger'
                    })
                })
                .finally(() => {
                    this.isLoading = false
                })
            }
  
        },

        mounted(){
            
        }
    }
</script>