import axios from "axios";
import sycamoreServiceClient from ".";


export const verifyIPPISToken = async token => {
    const response = await sycamoreServiceClient({
        url: `/customer/profile/token/name?token=${token}`, 
        method: 'get'
    });

    return {
        status: response.status,
        data: response.data,
    };
};

export const updateIPPISCustomer = async (payload, token) => {
    const response = await sycamoreServiceClient({
        url: `/customer/edit/profile/misc?token=${token}`, 
        method: 'put',
        data: payload
    });

    return {
        status: response.status,
        data: response.data,
    };
};

export const resolveBank = async (payload, token) => {
    const response = await sycamoreServiceClient({
        url: `/customer/accounts/resolve/misc?token=${token}`, 
        method: 'post',
        data: payload
    });

    return {
        data: response.data,
    };
};

export const getBanks = async () => {
    const response = await sycamoreServiceClient({
        url: `/misc/banks`, 
        method: 'get'
    });

    return {
        data: response.data,
    };

};

// For test purpose only
export const getTest = async () => {
  const response = await axios.get(process.env.VUE_APP_API_TEST_URL);

  return {
    data: response.data.data,
  };

};

