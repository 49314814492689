<template>
    <div class="w-screen h-screen absolute left-0 top-0 flex justify-center items-center bg-underlay" >
        <div class="p-8 py-12 bg-white rounded-3xl component-shadow w-11/12 m-auto md:w-25r" >
            <div>
                <Check class="m-auto success-icon" />
            </div>
            <div style="margin-top: 1.5rem;">
                <h3 class="text-center text-lg md:text-xl font-medium text-gray-10">Submitted</h3>
                <div class="mt-4 text-xxs md:text-xs text-gray-46 leading-4 md:leading-5">
                    <p class="">
                        Thank you for providing your information. Rest assured that your personal information will be kept confidential and handled securely.
                    </p>
                    <p class="pt-4">
                        <a href="tel:017003497" class="text-success">Contact us</a>
                        if you have questions. Thanks for choosing Sycamore
                    </p>
                </div>
                <div class="mt-8">
                    <a href="https://sycamore.ng/"
                        role="button"
                        class = "button is-primary text-base font-medium"
                    >Done</a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Check from "../svg/SuccessCheck";

export default {
    components: {
        Check
    },
}
</script>

<style scoped>
.bg-underlay {
    background: radial-gradient(100% 234.68% at 0% 100%, #F4FFF9 22.06%, #F4FFF9 88.31%)
}

.component-shadow {
    box-shadow: #daf1e3  0px 2px 4px 0px, #def5e6  0px 4px 12px 4px;
}

    
.success-icon {
    /* width: 3.5rem !important; */
    /* height: 3.5rem !important; */
    margin: auto !important;
    animation-duration: 2s;
    animation-name: bounceInDown
}

h3 {
    animation-duration: 2s;
    animation-name: scaleIn
}
.button {
    background-color: #26C165;
    border-radius: 8px;
    width: 100%;
    height: 3rem;
    display: flex;
    justify-content: center;
    align-items: center;
}

.button:hover{
    background-color: #26C165;

}

@keyframes bounceInDown {
    0% {
        opacity: 0;
        -webkit-transform: translateY(-20px);
    }
    60% {
        opacity: 1;
        -webkit-transform: translateY(5px);
    }
    80% {
        -webkit-transform: translateY(-2px);
    }
    100% { 
        -webkit-transform: translateY(0);
    }
}

@keyframes scaleIn {
    0% {
        opacity: 0;
        transform: scale(1.2);;
    }
    50% {
        opacity: 1;
        transform: scale(0.8);
    }
    100% { 
        transform: scale(1);
    }
}

</style>