<template>
    <div>
        <div class="card">
            <div class="card-content">
        <div class="pb-6">
            <p class="text-center font-semibold has-text-primary text-xl">Update Loans</p>
        </div>
        <form action="" @submit.prevent="updateLoans" method="PUT">
            <section class="columns is-multiline">
                <div class="column is-6">
                    <b-field label="amount requested">
                        <b-input v-model="details.amount_requested"></b-input>
                    </b-field>
                </div>
                <div class="column is-6">
                    <b-field label="amount_disbursed">
                        <b-input v-model="details.amount_disbursed"></b-input>
                    </b-field>
                </div>
                <div class="column is-12">
                    <b-field label="amount_paid">
                        <b-input v-model="details.amount_paid"></b-input>
                    </b-field>
                </div>
                <div class="column is-6">
                    <b-field label="total amount">
                        <b-input v-model="details.total_amount"></b-input>
                    </b-field>
                </div>
                
                <div class="column is-6">
                    <b-field label="rate">
                        <b-input v-model="details.rate"></b-input>
                    </b-field>
                </div>

                <div class="column is-6">
                    <b-field label="percentage_funded">
                        <b-input v-model="details.percentage_funded"></b-input>
                    </b-field>
                </div>

                <div class="column is-6">
                    <b-field label="duration">
                        <b-input v-model="details.duration"></b-input>
                    </b-field>
                </div>

                <div class="column is-6">
                    <b-field label="duration_type">
                        <b-input v-model="details.duration_type"></b-input>
                    </b-field>
                </div>

                <div class="column is-6">
                    <b-field label="loan type">
                        <b-input v-model="details.loan_type"></b-input>
                    </b-field>
                </div>

                <div class="column is-6">
                    <b-field label="product_type_id">
                        <b-input v-model="details.product_type_id"></b-input>
                    </b-field>
                </div>

                <div class="column is-6">
                    <b-field label="repayment_default">
                        <b-input v-model="details.repayment_default"></b-input>
                    </b-field>
                </div>

                <div class="column is-6">
                    <b-field label="Investment parent id">
                        <b-input v-model="details.investment_parent_id"></b-input>
                    </b-field>
                </div>

                <div class="column is-6">
                    <b-field label="contract_status">
                        <b-input v-model="details.contract_status"></b-input>
                    </b-field>
                </div>

                <div class="column is-6">
                    <b-field label="reason">
                        <b-input v-model="details.reason"></b-input>
                    </b-field>
                </div>

                <div class="column is-6">
                    <b-field label="loan officer">
                    <div class="control">
                        <div 
                            class="select is-fullwidth"
                        >
                            <select name="loan_officer" v-model="details.loan_officer">
                                <option 
                                    v-for="staff in staffs" 
                                    :key="staff.id" 
                                    :value="staff.id"
                                >
                                    {{staff.id }}: {{ staff.name }}
                                </option>
                            </select>
                        </div>
                    </div>
                    </b-field>

                </div>

                <div class="column is-6">
                    <b-field label="assigned by">
                        <div class="control">
                            <div 
                                class="select is-fullwidth"
                            >
                                <select name="loan_officer" v-model="details.assigned_by">
                                    <option 
                                        v-for="staff in staffs" 
                                        :key="staff.id" 
                                        :value="staff.id"
                                    >
                                        {{staff.id }}: {{ staff.name }}
                                    </option>
                                </select>
                            </div>
                        </div>
                    </b-field>
                </div>

                <div class="column is-6">
                    <b-field label="risk officer">
                        <div class="control">
                            <div 
                                class="select is-fullwidth"
                            >
                                <select name="loan_officer" v-model="details.risk_officer">
                                    <option 
                                        v-for="staff in staffs" 
                                        :key="staff.id" 
                                        :value="staff.id"
                                    >
                                        {{staff.id }}: {{ staff.name }}
                                    </option>
                                </select>
                            </div>
                        </div>
                    </b-field>
                </div>

                <div class="column is-6">
                    <b-field label="risk assigned by">
                        <div class="control">
                            <div 
                                class="select is-fullwidth"
                            >
                                <select name="risk_assigned_by" v-model="details.risk_assigned_by">
                                    <option 
                                        v-for="staff in staffs" 
                                        :key="staff.id" 
                                        :value="staff.id"
                                    >
                                        {{staff.id }}: {{ staff.name }}
                                    </option>
                                </select>
                            </div>
                        </div>
                    </b-field>
                </div>

                <div class="column is-6">
                    <b-field label="disbursement_date">
                        <b-input v-model="details.disbursement_date"></b-input>
                    </b-field>
                </div>
                <div class="column is-6">
                    <b-field label="next_repayment_date">
                        <b-input v-model="details.next_repayment_date"></b-input>
                    </b-field>
                </div>
                <div class="column is-6">
                    <b-field label="last_repayment_date">
                        <b-input v-model="details.last_repayment_date"></b-input>
                    </b-field>
                </div>
                
                <div class="column is-6">
                    <b-field label="created by staff">
                        <div class="control">
                            <div 
                                class="select is-fullwidth"
                            >
                                <select name="created_by_staff" v-model="details.created_by_staff">
                                    <option 
                                        v-for="staff in staffs" 
                                        :key="staff.id" 
                                        :value="staff.id"
                                    >
                                        {{staff.id }}: {{ staff.name }}
                                    </option>
                                </select>
                            </div>
                        </div>
                    </b-field>
                </div>

                <div class="column is-6">
                    <b-field label="created by agent">
                        <div class="control">
                            <div 
                                class="select is-fullwidth"
                            >
                                <select name="created_by_agent" v-model="details.created_by_agent">
                                    <option 
                                        v-for="staff in staffs" 
                                        :key="staff.id" 
                                        :value="staff.id"
                                    >
                                        {{staff.id }}: {{ staff.name }}
                                    </option>
                                </select>
                            </div>
                        </div>
                    </b-field>
                </div>

                <div class="column is-6">
                    <b-field label="updated_by_staff">
                        <div class="control">
                            <div 
                                class="select is-fullwidth"
                            >
                                <select name="updated_by_staff" v-model="details.updated_by_staff">
                                    <option 
                                        v-for="staff in staffs" 
                                        :key="staff.id" 
                                        :value="staff.id"
                                    >
                                        {{staff.id }}: {{ staff.name }}
                                    </option>
                                </select>
                            </div>
                        </div>
                    </b-field>
                </div>

                <div class="column is-6">
                    <b-field label="last_reminder_date">
                        <b-input v-model="details.last_reminder_date"></b-input>
                    </b-field>
                </div>
                <div class="column is-6">
                    <b-field label="last_status">
                        <b-input v-model="details.last_status"></b-input>
                    </b-field>
                </div>
                <div class="column is-6">
                    <b-field label="parent_loan_id">
                        <b-input v-model="details.parent_loan_id"></b-input>
                    </b-field>
                </div>
            </section>
            <div class="mt-6">
                <button type="submit"
                class="button is-success"
                :class="isLoading ? 'is-loading' : ''"
                :disabled="isLoading ? true : false"
                >update</button>
            </div>
        </form>
    </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
    export default {
        props:{
            details:Object,
            staffs:Array
        },
        data() {
            return {
                isLoading: false,
            }
        },

        computed:{
            params(){
                let obj={
                    amount_requested: this.details.amount_requested,
                    amount_disbursed: this.details.amount_disbursed,
                    amount_paid: this.details.amount_paid,
                    total_amount: this.details.total_amount,
                    rate: this.details.rate, 
                    percentage_funded: this.details.percentage_funded,
                    duration: this.details.duration ,
                    duration_type: this.details.duration_type,
                    loan_type: this.details.loan_type,
                    product_type_id: this.details.product_type_id, 
                    repayment_default: this.details.repayment_default, 
                    reason: this.details.reason, 
                    loan_officer: this.details.loan_officer ,
                    assigned_by: this.details.total_withdrawn, 
                    risk_assigned_by: this.details.risk_assigned_by,
                    disbursement_date: this.details.disbursement_date,
                    next_repayment_date: this.details.next_repayment_date,
                    last_repayment_date: this.details.last_repayment_date,
                    created_by_staff: this.details.created_by_staff,
                    created_by_agent: this.details.created_by_agent,
                    updated_by_staff: this.details.updated_by_staff,
                    last_reminder_date: this.details.last_reminder_date,
                    last_status: this.details.risk_officer,
                    parent_loan_id: this.details.parent_loan_id
                    }

                    for (var propName in obj) {
                        if (obj[propName] === null || obj[propName] === undefined) {
                        delete obj[propName];
                        }
                    }
                    return obj
            }
        },


        methods:{
           
            updateLoans(){
                this.isLoading = true
                axios.put(process.env.VUE_APP_ROOT_API+`/loans/${this.details.id}`,
                    this.params
                )
                .then(response => {
                    this.$buefy.toast.open({
                        message: response.data.message,
                        type: 'is-success'
                    })
                    this.$emit('clicked', 'someValue')
                    // this.$router.go()
                })
                .catch(e => {
                    this.$buefy.toast.open({
                        message: "An error occured",
                        type: 'is-danger'
                    })
                })
                .finally(() => {
                    this.isLoading = false
                })
            }
  
        },

        mounted(){
            
        }
    }
</script>