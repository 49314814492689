<template>
  <div class="w-screen h-screen flex items-center justify-center" style="background: linear-gradient(180deg, #F6AB19 0%, rgba(246, 171, 25, 0) 100%);">
    <img src="@/assets/images/logo.png" width="30%" />
  </div>
</template>

<script>
export default {
  name: 'Home'
}
</script>
