<template>
    <div class="p-6">
        <div class="mb-4">
            <UpdateLoans @clicked="onClickChild"  :staffs="staffs" :details="loanDetails"/>
        </div>

        
    </div>
</template>

<script>
import UpdateLoans from '@/components/UpdateLoans'

    export default {
  components: { 
      UpdateLoans,
      },
        data() {
            return {
            }
        },

        props: {
            loanDetails: {
                type: Object,
            },
            staffs:{
                type:Object
            }
        }
    }
</script>
