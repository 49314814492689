<template>
    <div>

        <div class="columns is-multiline">
            <div class="column is-12 my-12 px-12">
                <b-table
                    :data="investments"
                    :paginated="true"
                    :per-page="10"
                    :pagination-rounded="true"
                    :bordered="false"
                    :striped="true"
                    :hoverable="true"
                    detailed
                    detail-key="id"
                    detail-transition="fade"
                >
                    <b-table-column field="name" label="Name" v-slot="props">
                        {{ props.row.name}}
                    </b-table-column>

                    <b-table-column field="principal_amount" width="250" label="Principal amount" v-slot="props">
                        {{ props.row.Investments[0].principal_amount }}
                    </b-table-column>

                    <b-table-column field="status" width="250" label="Investment status" v-slot="props">
                        {{ props.row.Investments[0].status }}
                    </b-table-column>
                    
                    <b-table-column field="button" label="Button" v-slot="props">
                        <p class="control">
                            <b-tooltip label="Open detail"
                                type="is-primary is-light"
                            >
                                <button
                                    @click="toggleModals(props.row.Investments)"
                                    class="button is-small"
                                >
                                    <span class="icon is-small">
                                        <i class="ri-list-settings-line ri-lg"></i>
                                    </span>
                                </button>
                            </b-tooltip>
                        </p>
                    </b-table-column>
                    
                    <template #detail="props">
                        <div class="card border-2 rounded-lg">
                            <div class="card-content">
                                <ul class="">
                                    <li class="flex justify-between text-xs pb-2 mb-2 border-b-2 text-gray-500">
                                        <span class="text-black">Owner</span>
                                        <span>{{ props.row.Investments[0].owner || 'None' }}</span>
                                    </li>

                                    <li class="flex justify-between text-xs pb-2 mb-2 border-b-2 text-gray-500">
                                        <span class="text-black">id</span>
                                        <span>{{ props.row.Investments[0].id || 'None' }}</span>
                                    </li>

                                    <li class="flex justify-between text-xs pb-2 mb-2 border-b-2 text-gray-500">
                                        <span class="text-black">investment id</span>
                                        <span>{{ props.row.Investments[0].investment_id || 'None' }}</span>
                                    </li>

                                    <li class="flex justify-between text-xs pb-2 mb-2 border-b-2 text-gray-500">
                                        <span class="text-black">principal amount</span>
                                        <span>{{ props.row.Investments[0].principal_amount || 'None' }}</span>
                                    </li>
                                    
                                    <li class="flex justify-between text-xs pb-2 mb-2 border-b-2 text-gray-500">
                                        <span class="text-black">tenure</span>
                                        <span>{{ props.row.Investments[0].tenure  || 'None' }}</span>
                                    </li>
                                    
                                    <li class="flex justify-between text-xs pb-2 mb-2 border-b-2 text-gray-500">
                                        <span class="text-black">tenure type</span>
                                        <span>{{ props.row.Investments[0].tenure_type || 'None' }}</span>
                                    </li>
                                    
                                    <li class="flex justify-between text-xs pb-2 mb-2 border-b-2 text-gray-500">
                                        <span class="text-black">interest rate</span>
                                        <span>{{ props.row.Investments[0].interest_rate || 'None' }}</span>
                                    </li>
                                    
                                    <li class="flex justify-between text-xs pb-2 mb-2 border-b-2 text-gray-500">
                                        <span class="text-black">interest payment type</span>
                                        <span>{{ props.row.Investments[0].interest_payment_type || 'None' }}</span>
                                    </li>
                                    
                                    <li class="flex justify-between text-xs pb-2 mb-2 border-b-2 text-gray-500">
                                        <span class="text-black">Start date</span>
                                        <span>{{ new Date(props.row.Investments[0].start_date).toLocaleDateString() || 'None' }}</span>
                                    </li>

                                    <li class="flex justify-between text-xs pb-2 mb-2 border-b-2 text-gray-500">
                                        <span class="text-black">end date</span>
                                        <span>{{ new Date(props.row.Investments[0].end_date).toLocaleDateString() || 'None' }}</span>
                                    </li>

                                    <li class="flex justify-between text-xs pb-2 mb-2 border-b-2 text-gray-500">
                                        <span class="text-black">maturity_date </span>
                                        <span>{{ new Date(props.row.Investments[0].maturity_date).toLocaleDateString() || 'None' }}</span>
                                    </li>
                                    
                                    <li class="flex justify-between text-xs pb-2 mb-2 border-b-2 text-gray-500">
                                        <span class="text-black">product type id</span>
                                        <span>{{ props.row.Investments[0].product_type_id || 'None' }}</span>
                                    </li>
                                    
                                    <li class="flex justify-between text-xs pb-2 mb-2 border-b-2 text-gray-500">
                                        <span class="text-black">last_accrued</span>
                                        <span>{{ props.row.Investments[0].last_accrued || 'None' }}</span>
                                    </li>
                                    
                                    <li class="flex justify-between text-xs pb-2 mb-2 border-b-2 text-gray-500">
                                        <span class="text-black">total_accrued</span>
                                        <span>{{ props.row.Investments[0].total_accrued || 'None' }}</span>
                                    </li>
                                    
                                    <li class="flex justify-between text-xs pb-2 mb-2 border-b-2 text-gray-500">
                                        <span class="text-black">investment agent id</span>
                                        <span>{{ props.row.Investments[0].investment_agent_id || 'None' }}</span>
                                    </li>

                                    <li class="flex justify-between text-xs pb-2 mb-2 border-b-2 text-gray-500">
                                        <span class="text-black">investment parent id</span>
                                        <span>{{ props.row.Investments[0].investment_parent_id || 'None' }}</span>
                                    </li>

                                    <li class="flex justify-between text-xs pb-2 mb-2 border-b-2 text-gray-500">
                                        <span class="text-black">policy number</span>
                                        <span>{{ props.row.Investments[0].policy_number || 'None' }}</span>
                                    </li>

                                    <li class="flex justify-between text-xs pb-2 mb-2 border-b-2 text-gray-500">
                                        <span class="text-black">last reminder date</span>
                                        <span>{{ props.row.Investments[0].last_reminder_date || 'None' }}</span>
                                    </li>

                                    <li class="flex justify-between text-xs pb-2 mb-2 border-b-2 text-gray-500">
                                        <span class="text-black">total withdrawn</span>
                                        <span>{{ props.row.Investments[0].total_withdrawn || 'None' }}</span>
                                    </li>

                                    <li class="flex justify-between text-xs pb-2 mb-2 border-b-2 text-gray-500">
                                        <span class="text-black">preferred daily repayment time</span>
                                        <span>{{ props.row.Investments[0].preferred_daily_repayment_time || 'None' }}</span>
                                    </li>

                                    <li class="flex justify-between text-xs pb-2 mb-2 border-b-2 text-gray-500">
                                        <span class="text-black">offer link</span>
                                        <span>{{ props.row.Investments[0].offer_link || 'None' }}</span>
                                    </li>

                                    <li class="flex justify-between text-xs pb-2 mb-2 border-b-2 text-gray-500">
                                        <span class="text-black">contract status</span>
                                        <span>{{ props.row.Investments[0].contract_status || 'None' }}</span>
                                    </li>

                                </ul>

                            </div>
                        </div>
                    </template>
                </b-table>
            </div>
        </div>

        <b-sidebar
            type="is-light"
            :fullheight="fullheight"
            :overlay="overlay"
            :right="right"
            :can-cancel="cancancel"
            v-model="open"
        >
            <div class="tw-p-4">
                <button
                    @click="open = false"
                    class="delete is-large has-background-danger"
                >
                </button>

                <sidePanelInvestment  @clicked="onClickChild" :products="products" :staffs="staffs" :investmentDetails="investmentDetails" />
            </div>
        </b-sidebar>
    </div>
</template>

<script>
import axios from 'axios'
import sidePanelInvestment from '@/components/sidePanelInvestment'

    export default {
        components:{
            sidePanelInvestment,
        },
        data() {
            return {
                isCardModalActive: false,
                investments:'',
                component: '',
                investmentDetails: '',
                staffs: '',
                products: '',
                open: false,
                overlay: false,
                fullheight: true,
                fullwidth: true,
                right: true,
                cancancel: ['escape'],
                isLoading: false,
            }
        },

        computed:{
        },

        methods:{
            cardModal() {
                this.$buefy.modal.open({
                    parent: this,
                    component: ModalForm,
                    hasModalCard: true,
                    customClass: 'custom-class custom-class-2',
                    trapFocus: true
                })
            },

            openModal(prop, modal){
                this.isCardModalActive= true
                this.currentData=prop
                this.component=modal
            },

            toggleModals(details) {
                this.investmentDetails = details
                this.open = true;
            },

            getAllInvestments(){
                axios.get(process.env.VUE_APP_ROOT_API+`/customers/all/investments`)
                .then(response => {
                    this.investments = response.data.data
                })
                .catch(e => {
                    this.errors.push(e)
                })
            },
            getAllStaff(){
                axios.get(process.env.VUE_APP_ROOT_API+`/staff`)
                .then(response => {
                    this.staffs = response.data.data
                })
                .catch(e => {
                    this.errors.push(e)
                })
            },
            
            getproducts(){
                axios.get(process.env.VUE_APP_ROOT_API+`/product-types`)
                .then(response => {
                    this.products = response.data.data
                })
                .catch(e => {
                    this.errors.push(e)
                })
            }
            
        },
        created() {
            this.getAllInvestments()
            this.getAllStaff()
            this.getproducts()
            
        }
    }
</script>