import sycamoreServiceClient from '.';

export const verifyToken = async body => {
  const response = await sycamoreServiceClient({
    url: '/auth/verify_token_validity',
    method: 'post',
    data: body,
  });

  return {
    status: response.status,
    data: response.data,
  };
};

export const resetPassword = async (body, type) => {
  const response = await sycamoreServiceClient({
    url: `/auth/${type}/resetPassword`,
    method: 'post',
    data: body,
  });

  return {
    status: response.status,
    data: response.data,
  };
};

export const verifyEmail = async body => {
  const response = await sycamoreServiceClient({
    url: '/auth/emailverification',
    method: 'post',
    data: body,
  });

  return {
    status: response.status,
    data: response.data,
  };
};
