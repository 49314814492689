<template>
    <div>
        <div class="h-screen flex items-center" v-if="loadingStatus">
            <div class="m-auto">
                <Ripple :isLoading="loadingStatus" />
            </div>
        </div>

        <div v-else class="bg-white lg:mx-60">
            <div v-if="activeTab == 0">
                <div class="flex justify-between items-center mb-12 pr-4 lg:pr-12">
                    <div class="w-5/12 lg:w-4/12">
                        <img src="../../assets/images/letterhead_logo.png">
                    </div>

                    <div class="">
                        <span class="text-black text-xs md:text-sm">
                            Offer letter {{ investment ? investment.contract_status : '' }}
                        </span>
                        <p class="text-xs md:text-sm">RC : 1505897</p>
                    </div>
                </div>

                <div class="md:w-10/12 m-auto">
                    <div class="mt-12 px-4 lg:px-12">
                        <div class="flex flex-col text-xs md:text-sm mb-5">
                            <div class="mb-6">
                                <p class="">
                                    {{moment(investment.start_date).format('DD MMMM YYYY')}}. 
                                </p>
                                <p class="">
                                    Policy No. 
                                    <span>– {{ investment.investment_id || 'No policy number' }}</span>
                                </p>
                                <p class="font-bold">
                                    Dear {{ investment.Customer ? investment.Customer.name : 'No name' }}
                                </p>
                            </div>
                            
                            <div class="text-center">
                                <p class="font-semibold">RE: Investment Agreement with Sycamore Integrated Services Limited </p>
                                <p class="" v-if="investment.start_date">We are pleased to confirm receipt of your funds with us on the following terms and conditions: </p>
                                <p class="" v-else>We are pleased to offer you this proposed agreement. Kindly review and confirm if the terms below are acceptable to you. </p>
                            </div>
                        </div>

                        <div class="mb-6">
                            <table class="">
                                <thead>
                                    <tr class="text-xs md:text-sm">
                                        <th class="text-xs md:text-sm border-t-0 border-r-0 border-l-0 border-b-2">Lender Type </th>
                                        <th class="text-xs md:text-sm border-t-0 border-b-2 border-r-0 border-l-0 font-normal">{{investment.ProductType? investment.ProductType.name : 'N/A'}}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td class="text-xs md:text-sm border-t-0 border-r-0 borde border-l-0 border-b-0 font-semibold">Amount Invested</td>
                                        <td class="text-xs md:text-sm border-t-0 border-b-0 border-r-0 border-l-2">₦{{ investment.principal_amount?  Number(investment.principal_amount.toFixed(2)).toLocaleString() : 'N/A'}}</td>
                                    </tr>
                                    <tr>
                                        <td class="text-xs md:text-sm bg-transparent border-t-0 border-r-0 borde border-l-0 border-b-0 font-semibold">Value Date</td>
                                        <td class="text-xs md:text-sm border-t-0 border-b-0 border-r-0 border-l-2">{{investment.start_date? moment(investment.start_date).format('DD MMMM YYYY') : 'N/A'}}</td>
                                    </tr>
                                    <tr>
                                        <td class="text-xs md:text-sm bg-transparent border-t-0 border-r-0 borde border-l-0 border-b-0 font-semibold">Maturity Date</td>
                                        <td class="text-xs md:text-sm border-t-0 border-b-0 border-r-0 border-l-2">{{ investment.maturity_date? moment(investment.maturity_date).format('DD MMMM YYYY') : 'N/A'}}</td>
                                    </tr>
                                    <tr>
                                        <td class="text-xs md:text-sm border-t-0 border-r-0 borde border-l-0 border-b-0 font-semibold">Payment Pattern</td>
                                        <td class="text-xs md:text-sm border-t-0 border-b-0 border-r-0 border-l-2 capitalize">{{investment.interest_payment_type? investment.interest_payment_type: 'N/A'}}</td>
                                    </tr>
                                    <tr>
                                        <td class="text-xs md:text-sm border-t-0 border-r-0 borde border-l-0 border-b-0 font-semibold">Tenor</td>
                                        <td class="text-xs md:text-sm border-t-0 border-b-0 border-r-0 border-l-2">{{`${investment.tenure} ${investment.tenure_type}`}}</td>
                                    </tr>
                                    <tr>
                                        <td class="text-xs md:text-sm border-t-0 border-r-0 borde border-l-0 border-b-0 font-semibold">Interest Rate (p.a.)</td>
                                        <td class="text-xs md:text-sm border-t-0 border-b-0 border-r-0 border-l-2">{{investment.interest_rate}}%</td>
                                    </tr>
                                    <tr v-if="investment.tenure_type == 'months'">
                                        <td class="text-xs md:text-sm border-t-0 border-r-0 borde border-l-0 border-b-0 font-semibold">Monthly Interest</td>
                                        <td class="text-xs md:text-sm border-t-0 border-b-0 border-r-0 border-l-2">₦{{Number(investment.monthly_interest).toLocaleString()}}</td>
                                    </tr>
                                    <tr>
                                        <td class="text-xs md:text-sm border-t-0 border-r-0 borde border-l-0 border-b-0 font-semibold">Gross Interest Accruable</td>
                                        <td class="text-xs md:text-sm border-t-0 border-b-0 border-r-0 border-l-2">₦{{Number(interestInfo.grossInterest.toFixed(2)).toLocaleString()}}</td>
                                    </tr>
                                    <tr>
                                        <td class="text-xs md:text-sm border-t-0 border-r-0 borde border-l-0 border-b-0 font-semibold">Withholding Tax</td>
                                        <td class="text-xs md:text-sm border-t-0 border-b-0 border-r-0 border-l-2">₦{{Number(interestInfo.witholdingTax.toFixed(2)).toLocaleString()}}</td>
                                    </tr>
                                    <tr>
                                        <td class="text-xs md:text-sm border-t-0 border-r-0 borde border-l-0 border-b-0 font-semibold">Net Interest Accruable</td>
                                        <td class="text-xs md:text-sm border-t-0 border-b-0 border-r-0 border-l-2">₦{{Number(interestInfo.netInterest.toFixed(2)).toLocaleString()}}</td>
                                    </tr>
                                    <tr>
                                        <td class="text-xs md:text-sm border-t-0 border-r-0 borde border-l-0 border-b-0 font-semibold">
                                            {{investment.interest_payment_type == 'months'? 'Monthly payable interest' : 'Total Payable at Maturity'}}
                                        </td>
                                        <td class="text-xs md:text-sm border-t-0 border-b-0 border-r-0 border-l-2">
                                            ₦{{investment.interest_payment_type ==  'months'? Number(interestInfo.monthlyPayment.toFixed(2)).toLocaleString() : Number(interestInfo.netPayable.toFixed(2)).toLocaleString()}}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        <div class="mb-6">
                            <ol class="mt-8">
                                <li class="text-xs md:text-sm leading-6 flex text-justify pb-2">
                                    <span class="mr-2">1.</span> 
                                    This agreement is duly governed by laws of the Federal Republic of Nigeria.
                                </li>
                                <li class="text-xs md:text-sm leading-6 flex text-justify pb-2">
                                    <span class="mr-2">2.</span> 
                                    Any early liquidation (including part-liquidation) or termination of the contract before the above-stated maturity date shall trigger a penalty charge on the total net interest receivable payment. The associated penalty charge will depend on how many days from the investment start date the early liquidation request was made and will be deducted from any liquidation amount disbursed. (See below)
                                </li>
                                <li class="text-xs md:text-sm leading-6 flex text-justify pb-2">
                                    <span class="mr-2">3.</span> 
                                    Early liquidation notice should be made at least fourteen (14) days before the liquidation date.
                                </li>
                                <li class="text-xs md:text-sm leading-6 flex text-justify pb-2">
                                    <span class="mr-2">4.</span> 
                                    Please note that funds will be rolled over at the prevailing market rate if not stated before maturity.
                                </li>
                                <li class="text-xs md:text-sm leading-6 flex text-justify pb-2">
                                    <span class="mr-2">5.</span> 
                                    That any error observed in the investment certificate will be communicated to the advisor through mail within three working days of its issue, otherwise the investment certificate is assumed to be accurate and free from errors.
                                </li>
                                <li class="text-xs md:text-sm leading-6 flex text-justify pb-2">
                                    <span class="mr-2">6.</span> 
                                    Please bear in mind that the new Interest Payout dates are the 11th and 28th of the month respectively
                                </li>
                            </ol>
                        </div>

                        <div class="mb-6">
                            <h1 class="font-semibold">
                                Applicable penalty charges associated with the number of days from the beginning of the debt investment start date, in the event that the investment is liquidated before maturity:
                            </h1>
                            
                            <ol class="mt-4 ml-2">
                                <li class="text-xs md:text-sm leading-6 flex text-justify pb-2">
                                    <span class="mr-2">1.</span> 
                                    0 to 45 days = 100% of total net interest over the life of investment.
                                </li>
                                <li class="text-xs md:text-sm leading-6 flex text-justify pb-2">
                                    <span class="mr-2">2.</span> 
                                    46 to 120 days = 75% of total net interest over the life of investment.
                                </li>
                                <li class="text-xs md:text-sm leading-6 flex text-justify pb-2">
                                    <span class="mr-2">3.</span> 
                                    120 to 160 days = 50% of total net interest over the life of investment.
                                </li>
                                <li class="text-xs md:text-sm leading-6 flex text-justify pb-2">
                                    <span class="mr-2">4.</span> 
                                    160 and above = 30% of total net interest over the life of investment.
                                </li>
                            </ol>
                        </div>

                        <div class="mb-6">
                            <p class="text-xs md:text-sm">
                                Yours faithfully, 
                            </p>

                            <p class="text-xs md:text-sm font-semibold pt-4 pb-4">
                                For Sycamore Integrated Solution Limited
                            </p>

                            <div class="columns is-mobile">
                                <div class="column is-5">
                                    <div class="w-6/12 md:w-4/12">
                                        <img src="../../assets/signature/tunde.svg" width="400" alt="">
                                    </div>
                                    <!-- <div class="pb-4">
                                        <p class="text-xs md:text-sm font-medium">Signature N/A</p>
                                    </div> -->
                                    <div>
                                        <p class="text-xs md:text-sm">Babatunde Akin-Moses</p>
                                        <p class="text-xs md:text-sm">Managing Director </p>
                                    </div>
                                </div>

                                <div class="column is-4-tablet is-offset-4-tablet is-5-mobile is-offset-2-mobile">
                                    <div class="flex justify-start">
                                        <div class="w-4/12 md:w-3/12">
                                            <img src="../../assets/signature/onyinye.svg" class="" alt="">
                                        </div>
                                    </div>
                                    <!-- <div v-else class="pb-4">
                                        <p class="text-xs md:text-sm font-medium">Signature N/A</p>
                                    </div> -->
                                    <div class="text-left">
                                        <p class="text-xs md:text-sm">Onyinye Okonji</p>
                                        <p class="text-xs md:text-sm">Executive Director</p> 
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="mb-6" v-if="token && investment.contract_status == 'generated'">
                            <div class="buttons is-centered">
                                <button 
                                    @click="handleDecideContract('accepted')" 
                                    :class="loadingStatus1 ? 'is-loading' : ''"
                                    :disabled="loadingStatus1 ? true : false"
                                    class="button is-success is-small   px-6"
                                >
                                    Accept
                                </button>
                                <button 
                                    @click="handleDecideContract('rejected')"  
                                    :class="loadingStatus2 ? 'is-loading' : ''" 
                                    :disabled="loadingStatus2 ? true : false"
                                    class="button is-danger is-small px-6"
                                >
                                    Decline
                                </button>
                            </div>
                        </div>

                        <hr>

                        <div class="text-center mt-4 pb-4">
                            <!-- <p class="text-xs md:text-sm leading-6 font-semibold">
                                <span class="mr-2">1 |</span> 
                                www.sycamore.ng 
                            </p> -->

                            <p class="text-xs md:text-sm leading-6 font-semibold">
                                The Board: Bade Aluko | Adeola Azeez | Oliver Nnona
                            </p>
                            <p class="text-xs md:text-sm leading-6 font-semibold">
                                Executive Directors: Babatunde Akin-Moses | Onyinye Okonji | Mayowa Adeosun
                            </p>
                            <p v-if="contacts" class="text-xs md:text-sm leading-6 font-semibold">
                                {{ contacts.address }}| {{ contacts.contact_email }} | {{ contacts.customer_service_number }} | www.sycamore.ng
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <div v-if="activeTab == 1">
                <TokenInvalid />
            </div>
        </div>  
    </div>
</template>

<script>
    import moment from "moment";
    import Ripple from "@/components/Ripple";
    import TokenInvalid from "@/components/TokenInvalid";
    import {fetchInvestment, decideContract} from "@/api/investment";
    import {getSycamoreContacts} from "@/api/contacts";

    export default {
        name: 'InvestmentContract',
        components:{
            Ripple,
            TokenInvalid
        },

        data() {
            return {
                loadingStatus: false,
                loadingStatus1: false,
                loadingStatus2: false,
                investment: {},
                errorStatus: {},
                contacts: null,
                activeTab: 0
            }
        },

        
    computed: {
        interestInfo() {
            const investment = this.investment;
            const interest = (investment.interest_rate / 100) * investment.principal_amount;
            const dailyInterest = interest / 365;
            const investmentDays = 30 * investment.tenure;
            const grossInterest = dailyInterest * investmentDays;
            const witholdingTax = (10 / 100) * grossInterest;
            const netInterest = grossInterest - witholdingTax;
            const netPayable = parseInt(investment.principal_amount, 10) + netInterest;
            const grossPayable = parseInt(investment.principal_amount, 10) + grossInterest;
            const monthlyPayment = netInterest / investment.tenure;
            return {
                witholdingTax,
                grossInterest,
                grossPayable,
                netInterest,
                netPayable,
                monthlyPayment
            }
        }
    },

    methods: {
        async getInvestment() {
            this.loadingStatus= true;

            try {
                const {offer_link} = this.$route.params
                const response = await fetchInvestment(offer_link)
                const {data} = response.data;
                if (data) {
                    this.investment = data;
                }
                this.activeTab = 0

            } catch (error) {
                this.activeTab = 1
                this.errorStatus = error.response.data

            } finally {
                this.loadingStatus= false;
            }
        },

        async handleDecideContract(status) {
            if (status == 'accepted') {
                this.loadingStatus1 = true;
            }

            if (status == 'rejected') {
                this.loadingStatus2 = true;
            }

            try {
                const {token} = this.$route.query
                const response = await decideContract({
                    investment_id: this.investment.id, 
                    status, 
                    token
                })

                if (response.data.success) {
                    this.getInvestment();
                }

            } catch (err) {
                this.$buefy.notification.open({
                    message: err.response.data.message,
                    duration: 7000,
                    progressBar: true,
                    type: 'is-danger',
                    pauseOnHover: true
                })

            } finally {
                this.loadingStatus1 = false;
                this.loadingStatus2 = false;
            }
        },

        moment: function (date) {
            return moment(date);
        },

        async getContacts(){
            const response = await getSycamoreContacts();
            this.contacts = response.data.contact;

        }
    },

    mounted() {
        this.getInvestment();
        this.getContacts();
    },

    created(){
        const {token} = this.$route.query;
        this.token = token;
    }
}
</script>
