import sycamoreServiceClient from '.';

export const fetchStatement = async token => {
    const response = await sycamoreServiceClient({
        url: `/transactions/statement/${token}`, 
        responseType: 'blob',
        method: 'get',
    });

    return {
        status: response.status,
        data: response.data,
    };
};