<template>
    <div class="max-w-full sm:max-w-md mx-auto inset-y-0 right-0 h-screen flex flex-col justify-center items-center">
        <div class="s-height shadow bg-white h-full w-full relative sm:rounded-lg"> 

            <div v-if="isLoading" class="pt-64" >
                <loader />
            </div>
            
            <div v-else-if="!isLoading && isTokenValid" class="h-full">
                <div v-if="!isMandateCreated" class="px-4 sm:px-8">
                    <div class="flex justify-between items-center mt-5 mb-3 mr-3">
                        <span>
                            <button 
                                v-if="currentTab > 0 && currentTab < 4"
                                @click="back"
                            >
                                <i class="ri-arrow-left-line text-gray-900" style="font-size: 1.7em"></i>
                            </button>
                        </span>
                        
                        <img src="@/assets/images/logo.png" width="100" height="auto" alt="" />
                    </div>

                    <div v-if="currentTab == 0">
                        <div>
                            <div class="text-center">
                                <p class="text-lg font-semibold text-gray-900 pb-1">Hello, {{mandatePayload.customer_name }}</p>
                                <p class="text-sm">Complete these 3 easy steps to activate a direct <br/> debit for your loan</p>
                            </div>

                            <div class="mt-8 mx-12">

                                <div
                                    class="flex items-center w-full mb-10"
                                    v-for="(step, index) in steps"
                                    :key="index"
                                >
                                    <div class="flex flex-col items-center justify-center self-start mr-4 bg-white border border-primary h-10 w-10 rounded-full p-2"
                                    >
                                        <div class="flex flex-col items-center justify-center bg-primary text-white h-7 w-7 rounded-full"
                                        >
                                            <p class="text-xl">{{ index + 1 }}</p>
                                        </div>
                                    </div>
                                    <div class="">
                                        <p class="text-gray07 font-semibold mb-2"> {{step.title}}</p>
                                        <p class="text-gray07 text-sm text-opacity-70">{{step.desc}}</p>
                                    </div>
                                </div>
                            </div>

                            <div class="absolute bottom-10 left-0 w-full px-5 sm:px-9">
                                <button 
                                    @click="currentTab = 1" 
                                    class="button is-fullwidth is-primary"
                                >Begin here!</button>
                            </div>
                        </div>
                    </div>

                    <div v-if="currentTab == 1" class="mt-5 px-4">
                        <div class="text-center">
                            <p class="text-sm font-semibold text-black  pb-3">Step 1</p>
                            <p class="text-lg font-semibold text-gray-900 pb-1">Upload signature</p>
                            <p class="text-sm"><span class="font-medium">{{mandatePayload.customer_name.split(' ')[0]}}</span>, upload an image of your signature.</p>
                        </div>

                        <div class="w-full mt-7">
                            <b-field>
                                <b-upload @input="handleFileChange($event)" accept=".jpg, .jpeg, .png" class="upload-container" :class="file !== null? 'uploaded' : ''" expanded>
                                    <a class="upload-button w-full h-full flex justify-center items-center">
                                        <span v-if="file == null" class="dds text-gray-600 flex justify-center items-center">
                                            <i class="ri-2x ri-upload-cloud-2-line font-light"></i>
                                            <span class="pl-2 font-medium">Click to upload</span>
                                        </span>
                                        <span v-else class="font-medium" style="color:#00a746">{{file.name}}</span>
                                    </a>
                                </b-upload>
                            </b-field>
                        </div>

                        <div v-if="isSigned" class="mt-6">
                            <div class="flex flex-col">
                                <p class="text-sm text-center pb-2">You can skip this step if your previous mandate was successfully activated.</p>
                                <button
                                    @click="currentTab = 2"
                                    class="text-sm rounded-full underline font-medium text-indigo-500"
                                >Click here to skip</button>
                            </div>
                        </div>

                        <div class="absolute bottom-10 left-0 w-full px-5 sm:px-9">
                            <button 
                                @click="uploadSignature()" 
                                class="button is-fullwidth is-primary"
                                :class="loadingStatus ? 'is-loading' : ''"
                                :disabled="loadingStatus || !file"
                            >Upload signature</button>
                        </div>
                    </div>

                    <div v-if="currentTab == 2" class="px-2">
                        <div class="text-center pb-5">
                            <p class="text-sm font-semibold text-black  pb-3">Step 2</p>
                            <p class="text-lg font-semibold text-black pb-1">Account information</p>
                            <p class="text-sm">Please click on "Ok" to proceed with this account</p>
                        </div>

                        <div 
                            class="m-auto w-11/12 h-36 p-5 border rounded-xl hover:border-primary double-box-shadow border-primary text-xs"
                        > 
                        <div class="flex justify-between w-full mb-2">
                            <div class="w-1/2">
                                <p>Bank</p>
                                <p class="pt-1 font-semibold text-black-29">{{mandateInfo.bank_name}}</p>
                            </div>
                            <div class="w-1/2">
                                <p>Account Number</p>
                                <p class="pt-1 font-semibold text-black-29">{{mandateInfo.account_number}}</p>
                            </div>
                        </div>

                        <div class="pt-2">
                            <p>Account name</p>
                            <p class="pt-1 font-semibold text-black-29">{{mandateInfo.account_name}}</p>
                        </div>

                        <div class="absolute bottom-10 left-0 w-full px-5 sm:px-9">
                            <button 
                                @click="currentTab = 3" 
                                class="button is-fullwidth is-primary"
                            >Ok</button>
                        </div>
                    </div>
                    </div>

                    <div v-if="currentTab == 3" class="overflow-y-scroll">
                        <div class="text-center">
                            <p class="text-sm font-semibold text-black pb-3">Step 3</p>
                            <p class="text-lg font-bold text-black pb-2">Confirm information</p>
                            <p class="pt-1 text-sm">
                                <span class="font-medium">{{mandatePayload.customer_name.split(' ')[0]}}</span>,
                                confirm that your details below are the same as the one linked to the bank account you provided.</p> 
                                
                            <p class="pt-2 text-sm"><span class="text-primary">Ignore</span> the correct fields and <span class="text-primary">edit</span> the incorrect fields.</p>
                        </div>

                        <form @submit.prevent="validate()" class="mt-7 mx-2">
                            <div class="field mt-5">
                                <label class="">Phone number</label>
                                <div class="control">
                                    <input class="input" v-model="mandatePayload.customer_phone" type="text" maxlength="11" placeholder="08111111111">
                                </div>
                            </div>

                            <div class="field mt-5">
                                <label class="">Email</label>
                                <div class="control">
                                    <input class="input" v-model="mandatePayload.email" type="text" placeholder="example@mail.com">
                                </div>
                            </div>

                            <div class="field mt-5">
                                <label class="">Address</label>
                                <div class="control">
                                    <input class="input" v-model="mandatePayload.customer_address" type="text" placeholder="53b Oduduwa way">
                                </div>
                            </div>

                            <div class="absolute bottom-5 left-0 w-full px-5 sm:px-9">
                                <button 
                                    type="submit" 
                                    class="button is-fullwidth is-primary"
                                    :class="loadingStatus1 ? 'is-loading' : ''"
                                    :disabled="loadingStatus1"
                                >Submit</button>
                            </div>
                        </form>
                    </div>
                </div>

                <div v-else class="h-full">
                    <div v-if="currentTab == 4"
                    class="flex flex-col justify-center items-center h-full w-full">
                        <div class="m-5 text-center">
                            <h3 class="mb-5 font-semibold text-2xl" style="color:#008536">Hurray!</h3>
                            
                            <p class="font-light text-sm" style="color:#4a6254">Your request has been initiated.</p>
                            <p class="font-light text-sm mt-4" style="color:#4a6254">
                                <span class="font-normal text-gray-800">Heads up!</span> You will receive an email from our friends at <span class="font-normal text-gray-800">Lendsqr</span> to help finish setting up your Direct Debit Mandate.
                                 Don't be alarmed, We've partnered with <span class="font-normal text-gray-800">Lendsqr</span> to ensure a seamless process for you.
                            </p>
                        </div>

                        <div class="px-5 pt-4 sm:px-9 w-full">
                            <button 
                                @click="viewMandateLetter()" 
                                class="button is-fullwidth is-primary"
                            >View mandate letter</button>
                        </div>
                    </div>

                    <div v-if="currentTab == 5" class="flex flex-col justify-center items-center h-full w-full">
                        <div class="mb-9">
                            <img src="@/assets/images/logo.png" class="m-auto" width="100" alt="" />
                        </div>
                        <div class="text-center text-sm">
                            <p class="pb-3">This session has been completed! But while you're here, you can</p>
                            <p class="underline"><a href="http://sycamore.ng" class="text-gray-900 text-xs">Visit Sycamore</a></p>
                            <p class="underline pt-2"><a href="https://bit.ly/download-sycamore" class="text-gray-900 text-xs">Sign in to Sycamore</a></p>
                        </div>
                    </div>
                </div>
            </div>

           <div v-else-if="!isTokenValid && !isLoading" 
                class="w-11/12 m-auto md:w-25r flex justify-center items-center" 
                style="height: 100%"
            >
                <div class="px-4 bg-white component-shadow">
                    <div class="text-center text-black">
                        <p class="text-base font-semibold pb-3">
                            Invalid Session
                        </p>
                        <p class="text-xs">
                            The session is expired or invalid
                        </p>
                        <p class="text-black text-xs">Kindly contact <a :href="`mailto:${contacts.contact_email}`" class="underline hover:text-primary text-black">{{ contacts.contact_email }}</a> for support</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { verifyCMMSToken, uploadCMMSSignature, createCMMSMandate, fetchMandate } from "@/api/mdd";
import { validator, onFileChange } from "@/plugins/validator";
import { errorNotification } from "@/plugins/notifier";
import loader from "@/components/loader.vue";
    import {getSycamoreContacts} from "@/api/contacts";
export default {
    components: { loader },
    data() {
        return {
            currentTab: 0,
            file: null,

            searchBank: '',
            account_number: '',
            allBanks: [],
            bank: null,
            account_name: '',
            letter: null,

            mandatePayload: {
                customer_name: '',
                customer_phone: '',
                email: '',
                customer_address: '',
                account_number: '',
                bank_id: '',
                loan_id: ''
            },

            mandateInfo: null,

            isLoading: true,
            isMandateCreated: false,
            loadingStatus: false,
            loadingStatus1: false,

            isTokenValid: false,

            isSigned: false,
            contacts: null,

            steps: [
               {
                title: 'Upload signature',
                desc: 'Scan and upload your signature'
                },{
                title: 'Confirm account details',
                desc: 'Confirm your salary account is correct'
                },{
                title: 'Confirm your information',
                desc: 'Confirm your information is correct'
                },
            ]
        }
    },

    watch: {
        account_number(value) {
            if(value.length == 10) {
                this.resolveAccount()
            }
        }
    },

    computed: {
        filterBanks() {
            return this.allBanks.filter(bank => {
                return (
                    bank.name
                        .toString()
                        .toLowerCase()
                        .indexOf(this.searchBank.toLowerCase()) >= 0
                )
            })
        },
    },

    methods: {
        back(){
            this.currentTab--
        },

        viewMandateLetter(){
            this.currentTab = 5
            window.open(this.letter, '_blank');
        },

        async fetchMandateLetter(reference_number){
            try {
                this.loadingStatus = true;
                
                const response = await fetchMandate({"reference_number": reference_number }, this.$route?.query?.token);

                const pdfBlob = new Blob([response.data], { type: 'application/pdf' });
                this.letter = URL.createObjectURL(pdfBlob);

                this.currentTab = 4
                this.isMandateCreated = true

            } catch (err) {
                errorNotification(err.response.data.message)

            } finally {
                this.loadingStatus1 = false;

            }
        },
        
        async createMandate(){
            this.loadingStatus1 = true;
            
            try {
                this.loadingStatus = true;
                const response = await createCMMSMandate(this.mandatePayload, this.$route?.query?.token);

                if (response.data.success) {
                    this.fetchMandateLetter(response.data.data.reference_number)
                }
            } catch (err) {
                errorNotification(err.response.data.message)
                this.loadingStatus1 = false;
            }

        },

        validate () {
            let validationResult = validator([
                {type: 'required|String', data: this.mandatePayload.customer_address, name: 'Address'},
                {type: 'required|email', data: this.mandatePayload.email, name: 'Email'},
                {type: 'required|number', data: this.mandatePayload.customer_phone, name: 'Phone number'},
                {type: 'required|string', data: this.mandatePayload.customer_name, name: 'Full name'},
            ])

            if (!validationResult) {
                this.createMandate()

            }
        },
        handleFileChange(value) {
            const canUpload = onFileChange(value)
            if(canUpload) {
                this.file = value
            } else {
                this.file = null
            }
        },
        async uploadSignature() {
            this.loadingStatus = true;
            let fd = new FormData();
            fd.append('file', this.file);
            try {
                const response = await uploadCMMSSignature(fd, this.$route?.query?.token);
                if (response.data.success) {
                    this.currentTab = 2;
                
                } 
            } catch(err) {
                this.$buefy.toast.open({
                    message: err.response.data.message,
                    type: 'is-danger'
                })
            } finally {
                this.loadingStatus = false;

            }
        },

        async verifyToken(token) {
            try {
                const res = await verifyCMMSToken({ userToken: token });
                this.mandateInfo = res.data.data

                Object.keys(this.mandatePayload).forEach((key) => {
                    this.mandatePayload[key] = res.data.data[key]
                });
                this.mandatePayload.customer_name = res.data.data.account_name;
                this.mandatePayload.customer_phone = this.mandatePayload.customer_phone.replace("+234", "0");
                
                this.isSigned = res.data.data.signed
                this.isTokenValid = true
                

            } catch (error) {
                this.isTokenValid = false;

            } finally {
                this.isLoading = false;
            }
        },

        async getContacts(){
            const response = await getSycamoreContacts();
            this.contacts = response.data.contact;
        }
    },

    mounted() {
        this.verifyToken(this.$route?.query?.token)
    }
}
</script>

<style lang=scss scoped>
.upload-container{
    height: 150px;
    width: 100%;
    border-radius: 7px;
    padding: 8px;
    aspect-ratio: 1;
    position: relative;
    clip-path: inset(0 round 30px 0 30px 0);
}

.h-screen{
    background-color: #eeeeee;
    width: 100%;
}

.upload-container::before, .uploaded::before {
  content: '';
  position: absolute; 
  right: -7px;
  top: -7px;
  left: -7px;
  bottom: -7px;
  border: 8px dashed #bcbaba;
  border-radius: 37px 0 37px 0;
  box-sizing: border-box;
    transition: background .2s ease-in-out, border .2s ease-in-out;
}

.upload-container:hover::before, .uploaded::before {
  border: 8px dashed #00bf4e;
}

.upload-button{
    position: relative;
    z-index: 2;
    background-color: #55555511;
    transition: background .2s ease-in-out, border .2s ease-in-out;
    border-radius: 28px 0 28px 0;
}

.upload-button:hover, .uploaded .upload-button{
    background-color: #d6eee1;
}

.upload-button:hover .dds {
    color: #00a746;
}

.s-height{
    height: 100vh;
    font-family: 'Poppins';
}

.s-width{
    width: 100vw;
}

@media screen and (min-width: 640px) {
    .s-height{
        height: 90vh;
    }

    .s-width{
        width: 400px;
    }
}

label{
    font-size: 13px;
    font-weight: 500;
    display: block;
    padding-bottom: 5px;
    color: #484848;
}

.button {
    height: 45px !important;
    border-width: 0.5px !important;
    border-radius: 8px !important;
    font-weight: 500 !important;
}

.input {
    height: 40px !important;
    font-size: 12px !important;
    color: #001800 !important;
    padding-left: 19px 1 !important;
    box-shadow: none !important
}

.double-box-shadow {
    box-shadow: rgba(224, 224, 224, 1) 3px 3px, rgba(245, 245, 245, 1) 7px 7px;
}
</style>