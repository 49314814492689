<template>
  <div
    class="max-w-full sm:max-w-md mx-auto inset-y-0 right-0 h-screen flex flex-col justify-center items-center"
  >
    <div
      class="s-height shadow bg-white relative w-full sm:rounded-lg pt-8 pb-6 px-5"
    >
      <div v-if="isLoading" class="pt-64">
        <loader />
      </div>

      <div v-else-if="!isLoading && isTokenValid">
        <div class="flex justify-between items-center mb-3 mr-3">
          <span>
            <button v-if="step > 0 && step < 2" @click="step--">
              <i
                class="ri-arrow-left-line text-gray-900"
                style="font-size: 1.5em"
              ></i>
            </button>
          </span>
          <img
            src="@/assets/images/logo.png"
            width="100"
            height="auto"
            alt=""
          />
        </div>
        <div v-if="isMandateCreated">
          <div class="mb-2 mt-10">
            <img src="@/assets/images/confetti.gif" class="w-28 h-28 mx-auto" />
          </div>
          <div class="text-center">
            <p class="text-base font-medium">
              You have successfully initiated direct debit mandate.
            </p>
            <p class="text-gray-800 text-sm mt-4 font-semibold">
              {{ instruction }}
            </p>
          </div>
          <div class="flex w-full space-x-4 mt-2">
            <div
              class="mt-4 p-4 border rounded-xl border-primary text-xs w-1/2 flex items-center"
              v-for="(bank, index) in banks"
              :key="index"
            >
              <img v-if="bank.icon" :src="bank.icon" class="w-8 h-8 mr-2" />
              <div>
                <p class="font-semibold">{{ bank.bank_name }}</p>
                <p class="mt-1 font-medium">{{ bank.account_number }}</p>
              </div>
            </div>
          </div>
          <div class="mt-6 text-center">
              <a
                target="_blank"
                href="https://bit.ly/download-sycamore"
                class="button is-primary is-fullwidth text-sm"
              >
                Sign in to Sycamore
              </a>
              <a
              target="_blank"
                href="https://sycamore.ng/"
                class="mt-4 text-center button is-outlined is-fullwidth text-sm"
              >
                Visit Sycamore
              </a>
          </div>
        </div>
        <div v-else>
          <div v-if="step == 0">
            <div class="text-center mt-5">
              <p class="text-lg font-semibold text-gray-900 pb-1">
                Hello, {{ customerInfo.account_name }}
              </p>
              <p class="text-sm">
                Complete these 2 steps to activate a direct <br />
                debit for your loan
              </p>
            </div>

            <div class="mt-8 mx-8">
              <div
                class="flex items-center w-full mb-10"
                v-for="(item, index) in steps"
                :key="index"
              >
                <div
                  class="flex flex-col items-center justify-center self-start mr-4 bg-white border border-primary h-8 w-8 rounded-full p-2"
                >
                  <div
                    class="flex flex-col items-center justify-center bg-primary text-white h-5 w-5 rounded-full"
                  >
                    <p class="text-sm">{{ index + 1 }}</p>
                  </div>
                </div>
                <div class="">
                  <p class="text-gray07 font-semibold mb-1.5">
                    {{ item.title }}
                  </p>
                  <p class="text-gray07 text-xs text-opacity-70">
                    {{ item.desc }}
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div v-else-if="step == 1">
            <div>
              <p class="text base text-center font-bold">Account Information</p>
              <p class="text-xs text-gray-6c mt-1">
                Kindly confirm the account details below. Click on
                <span class="text-primary">proceed</span> button if the details
                below are correct.
              </p>
            </div>
            <div
              class="mt-7 p-5 border rounded-xl double-box-shadow border-primary text-xs"
            >
              <div class="flex justify-between w-full mb-3 text-xs">
                <div class="w-7/12">
                  <p class="text-gray-6c">Bank</p>
                  <p class="mt-1 font-bold text-black-29">
                    {{ customerInfo.bank_name }}
                  </p>
                </div>
                <div class="w-5/12">
                  <p class="text-gray-6c">Account Number</p>
                  <p class="font-bold text-black-29 mt-1">
                    {{ customerInfo.account_number }}
                  </p>
                </div>
              </div>

              <div class="text-xs">
                <p class="text-gray-6c">Account name</p>
                <p class="font-bold text-black-29 mt-1">
                  {{ customerInfo.account_name }}
                </p>
              </div>
            </div>
          </div>

          <div v-else-if="step == 2">
            <div>
              <p class="text-base text-center font-bold">
                Personal Information
              </p>
              <p class="text-sm text-gray-6c mt-2 text-center">
                <span class="font-semibold">{{
                  customerInfo.account_name.split(" ")[0]
                }}</span
                >, Kindly confirm that your personal information below are the
                same as the one linked to the bank account in the previous
                screen.
              </p>

              <form class="mt-7" accept-charset="UTF-8" @submit.prevent="validate()">
                <div class="field mt-4">
                  <label class="pb-1.5 text-xs font-medium text-gray-00"
                    >Email address</label
                  >
                  <div class="control">
                    <input
                      class="input"
                      disabled
                      v-model="customerInfo.email"
                      type="text"
                      placeholder="Example@mail.com"
                    />
                  </div>
                </div>

                <div class="field mt-4">
                  <label class="pb-1.5 text-xs font-medium text-gray-00"
                    >Phone number</label
                  >
                  <div class="control">
                    <input
                      class="input"
                      v-model="mandatePayload.phone"
                      maxlength="11"
                      type="text"
                      placeholder="08012345678"
                    />
                  </div>
                </div>

                <div class="field mt-4">
                  <label class="pb-1.5 text-xs font-medium text-gray-00"
                    >Address</label
                  >
                  <div class="control">
                    <input
                      class="input"
                      v-model="mandatePayload.customer_address"
                      type="text"
                      placeholder="Address"
                    />
                  </div>
                </div>

                <div class="absolute bottom-10 left-0 w-full px-5 sm:px-9">
                  <button
                    type="submit"
                    class="button is-fullwidth is-primary"
                    :class="isLoading2 || isLoading3 ? 'is-loading' : ''"
                    :disabled="isLoading2 || isLoading3"
                  >
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>

          <div
            class="absolute bottom-10 left-0 w-full px-5 sm:px-9"
            v-if="step !== 2"
          >
            <button
              @click="step++"
              class="button is-fullwidth is-primary"
              :class="isLoading2 ? 'is-loading' : ''"
              :disabled="isLoading2"
            >
              Proceed
            </button>
          </div>
        </div>
      </div>

      <div
        v-else-if="!isTokenValid && !isLoading"
        class="w-11/12 m-auto md:w-25r flex justify-center items-center"
        style="height: 100%"
      >
        <div class="px-4 bg-white component-shadow">
          <div class="text-center text-black">
            <p class="text-base font-semibold pb-3">Access Denied</p>
            <p class="text-xs">You do not have access to this resource.</p>
            <a href="https://sycamore.ng/" class="text-black underline text-xs"
              >Learn about Sycamore</a
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import loader from "@/components/loader.vue";
import {
  verifyCMMSToken,
  updateCustomerDetails,
  createMonoMandate,
} from "@/api/mdd";
import { validator } from "@/plugins/validator";
import { errorNotification, successNotification } from "@/plugins/notifier";

export default {
  components: { loader },
  data() {
    return {
      customerInfo: null,
      step: 0,
      phoneNumber: null,
      instruction:"",
      banks: [],
      mandatePayload: {
        phone: '',
        customer_address: ''
      },

      isMandateCreated: false,
      isLoading: false,
      isSignedIn: false,
      isTokenValid: false,
      isLoading2: false,
      isLoading3: false,

      steps: [
        {
          title: "Confirm account details",
          desc: "Confirm that your salary account is correct",
        },
        {
          title: "Confirm personal information",
          desc: "Confirm and update your personal information is correct",
        },
      ],
    };
  },
  methods: {
    async getCustomerInfo(token) {
      try {
        this.isLoading = true;

        const response = await verifyCMMSToken({ userToken: token });

        this.customerInfo = response.data.data;
        this.mandatePayload = {
          customer_address: response.data.data.customer_address,
          phone : response.data.data.customer_phone.replace("+234","0")
        }
        this.isSignedIn = response.data.data.signed;
        this.isTokenValid = true;
        this.phoneNumber = response.data.data.customer_phone.replace("+234", "0");
      } catch (error) {
        this.isTokenValid = false;
      } finally {
        this.isLoading = false;
      }
    },

    async createMandate() {
      this.isLoading2 = true;

      try {
        this.loadingStatus = true;
        const response = await createMonoMandate(
          {
            loan_id: this.customerInfo.loan_id,
            account_number: this.customerInfo.account_number,
            bank_code: this.customerInfo.bank_code,
          },
          this.$route?.query?.token
        );

        if (response.data.success) {
          this.isMandateCreated = true;
          this.instruction = response.data.message;
          this.banks = response.data.data.banks;
          successNotification("Mandate initiated successfully");
        }
        this.isLoading2 = false;
      } catch (err) {
        errorNotification(err.response.data.message);
        this.isLoading2 = false;
      }
    },

    async updateCustomerInfo() {
      this.isLoading3 = true;

      try {
        const response = await updateCustomerDetails(
          {
            address: this.mandatePayload.customer_address,
            phone: this.mandatePayload.phone,
          },
          this.$route?.query?.token
        );

        if (response.data.success) {
          this.createMandate();
        }

        this.isLoading3 = false;
      } catch (err) {
        errorNotification(err.response.data.message);
        this.isLoading3 = false;
      }
    },

    validate() {
      let validationResult = validator([
        {
          type: "required|String",
          data: this.customerInfo.customer_address,
          name: "Address",
        },
        {
          type: "required|number",
          data: this.phoneNumber,
          name: "Phone number",
        },
      ]);

      if (!validationResult) {
        if(this.mandatePayload.phone !== this.phoneNumber || this.mandatePayload.customer_address !== this.customerInfo.customer_address) {
          this.updateCustomerInfo();
        } else {
          this.createMandate()
        }
      }
    },
  },

  mounted() {
    this.getCustomerInfo(this.$route?.query?.token);
  },

};
</script>

<style lang=scss scoped>
.h-screen {
  background-color: #eeeeee;
  width: 100%;
}
.s-height {
  height: 100vh;
  font-family: "Poppins";
}

.input {
  height: 40px !important;
  font-size: 12px !important;
  color: #001800 !important;
  padding-left: 19px 1 !important;
  box-shadow: none !important;
}
.input:focus {
  border-color: #26c165;
}
.s-width {
  width: 100vw;
}
@media screen and (min-width: 640px) {
  .s-height {
    height: 90vh;
  }

  .s-width {
    width: 400px;
  }
}
.double-box-shadow {
  box-shadow: rgba(224, 224, 224, 1) 3px 3px, rgba(245, 245, 245, 1) 7px 7px;
}
</style>