<template>
    <div>
        <div class="card">
            <div class="card-content">
        <div class="pb-6">
            <p class="text-center font-semibold has-text-primary text-xl">Update Staff info</p>
        </div>
        <form action="" @submit.prevent="updateStaff" method="PUT">
            <section class="columns is-multiline">
                <div class="column is-6">
                    <b-field label="Name">
                        <b-input v-model="details.name"></b-input>
                    </b-field>
                </div>
                <div class="column is-6">
                    <b-field label="email">
                        <b-input v-model="details.email"></b-input>
                    </b-field>
                </div>
                <div class="column is-12">
                    <b-field label="phone">
                        <b-input v-model="details.phone"></b-input>
                    </b-field>
                </div>
                <div class="column is-6">
                    <b-field label="dob">
                        <b-input v-model="details.dob" placeholder="1988-1-2"></b-input>
                    </b-field>
                </div>

                <div class="column is-6">
                    <b-field label="gender">
                    <div class="control">
                        <div 
                            class="select is-fullwidth"
                        >
                            <select name="gender" v-model="details.gender">
                                <option value="male">
                                    male
                                </option>

                                <option value="male">
                                    female
                                </option>
                            </select>
                        </div>
                    </div>
                    </b-field>
                </div>

                <div class="column is-6">
                    <b-field label="address">
                        <b-input v-model="details.address"></b-input>
                    </b-field>
                </div>

                <div class="column is-6">
                    <b-field label="state">
                        <b-input v-model="details.state"></b-input>
                    </b-field>
                </div>

                <div class="column is-6">
                    <b-field label="country">
                        <b-input v-model="details.country"></b-input>
                    </b-field>
                </div>

                <div class="column is-6">
                    <b-field label="designation">
                        <b-input v-model="details.designation"></b-input>
                    </b-field>
                </div>

                <div class="column is-6">
                    <b-field label="role">
                        <b-input v-model="details.role"></b-input>
                    </b-field>
                </div>

                <div class="column is-6">
                    <b-field label="status">
                        <b-input v-model="details.status"></b-input>
                    </b-field>
                </div>

            </section>
            <div class="mt-6">
                <button type="submit"
                class="button is-success"
                :class="isLoading ? 'is-loading' : ''"
                :disabled="isLoading ? true : false"
                >update</button>
            </div>
        </form>
    </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
    export default {
        props:{
            details:Object
        },
        data() {
            return {
                isLoading: false,
            }
        },

        computed:{
            params(){
                let obj={
                    email: this.details.email,
                    name: this.details.name,
                    phone: this.details.phone,
                    dob: this.details.dob,
                    gender: this.details.gender, 
                    address: this.details.address,
                    state: this.details.state ,
                    country: this.details.country,
                    designation: this.details.designation,
                    role: this.details.role, 
                    status: this.details.status, 
                    }

                    for (var propName in obj) {
                        if (obj[propName] === null || obj[propName] === undefined) {
                        delete obj[propName];
                        }
                    }
                    return obj
            }
        },


        methods:{
           
            updateStaff(){
                this.isLoading = true
                axios.put(process.env.VUE_APP_ROOT_API+`/staff/${this.details.id}`,
                    this.params
                )
                .then(response => {
                    this.$buefy.toast.open({
                        message: response.data.message,
                        type: 'is-success'
                    })
                    this.$emit('clicked', 'someValue')
                    // this.$router.go()
                })
                .catch(e => {
                    this.$buefy.toast.open({
                        message: "An error occured",
                        type: 'is-danger'
                    })
                })
                .finally(() => {
                    this.isLoading = false
                })
            }
  
        },

        mounted(){
            
        }
    }
</script>