<template>
    <div class="p-6">
        <div class="mb-4">
            <div v-for="(details, index) in customerDetails.Accounts" :key="index" class="mb-4">
                <accountDetails @clicked="onClickChild" :details="details" />
            </div>
        </div>

        <div class="mb-4">
            <nextOfKin @clicked="onClickChild" :details="customerDetails.NextOfKins" />
        </div>

        <div class="mb-4">
            <workDetails @clicked="onClickChild" :details="customerDetails.WorkInfos"/>
        </div>

        <div class="mb-4">
            <UpdateCustomer @clicked="onClickChild" :details="customerDetails"/>
        </div>

        
    </div>
</template>

<script>
import nextOfKin from '@/components/nextOfKin'
import UpdateCustomer from '@/components/UpdateCustomer'
import workDetails from '@/components/workDetails'
import accountDetails from '@/components/accountDetails'

    export default {
        components: { 
            accountDetails ,
            nextOfKin,
            workDetails,
            UpdateCustomer
            },
        data() {
            return {
            }
        },

        methods: {
            onClickChild (value) {
                this.$emit('clicked', 'someValue')
            }
        },

        props: {
            customerDetails: {
                type: Array,
            }
        }
    }
</script>