<template>
    <div>
        <pre>{{ dataX }}</pre>
        <pre>{{ errorStatus }}</pre>
    </div>
</template>

<script>
    import { getTest } from "@/api/ippis";

    export default {
        components:{},

        data() {
            return {
                dataX: [],
                errorStatus: null,
                loadingStatus: false,
            }
        },

        computed: {},

        methods: {
            async fetchTest() {
                try {
                    const data = await getTest()
                    this.dataX = data.data;

                } catch (error) {
                    this.errorStatus = error.response.data

                }
            },
        },

        mounted() {
            this.fetchTest();
        },

        created() {}
    }
</script>
