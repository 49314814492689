<template>
  <div style="width: 27rem" class="card py-4">
    <div class="card-content">
      <header class="">
        <p class="text-center font-bold text-base">
          {{ data.title }}
        </p>
      </header>
      <section class="mt-4">
        <p class="text-center text-sm pb-4">
          {{ data.subtitle }}
        </p>
        <b-input
          type="password"
          v-model="password"
          password-reveal
          placeholder="Your password"
          rounded
          custom-class="text-center"
          required
        >
        </b-input>
      </section>
      <footer class="mt-8 is-centered buttons">
        <b-button label="Cancel" @click="$emit('close')" type="is-warning" />
        <b-button
          :type="data.buttonLabel == 'accept' ? 'is-primary' : 'is-danger'"
          :label="data.buttonLabel"
          :disabled="this.password.length < 8"
          :loading="loading"
          class="capitalize"
          @click="acceptContract()"
        />
      </footer>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      password: "",
    };
  },

  methods: {
    acceptContract() {
        this.$emit("callback", this.password);
      }
  },

  props: {
    data: {
      type: Object,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
};
</script>


<style>
.control.has-icons-right .icon.is-right svg {
  width: 1.3rem;
  height: 1.3rem;
}
</style>