<template>
    <div class="page">
        <div class="p-8 md:w-8/12 md:m-auto">
            <div v-if="!isRegistrationSuccessful">
                <div v-if="isTokenValid && !loadingStatus1" class="p-w md:m-auto">
                    <div class="flex justify-end mb-3">
                        <img src="@/assets/images/logo.png" width="100" alt="" />
                    </div>

                    <div class="text-gray07">
                        <h2 class="text-base md:text-xl font-bold pb-4">Provide Information</h2>
                        <p class="text-xxs md:text-sm opacity-70">
                            Please provide the following information to ensure better services and secure your loan account. This information will be used for communication and emergency purposes only.
                        </p>
                    </div>

                    <form action="" @submit.prevent="validate">
                        <div class="mt-6">
                            <h3 class="text-sm md:text-xl font-bold pb-1 text-gray07">Personal Information</h3>

                            <div class="field items-center">
                                <label class="label">Email address 
                                    <span class="text-red-500">*</span>
                                </label>
                                <div class="w-full">
                                    <input
                                        class="input"
                                        type="text" 
                                        placeholder="janedoe@email.com"
                                        v-model="personal_email"
                                    >   
                                    <small v-if="errorMessage.personal_email" class="transition text-xxs text-red-400">
                                        {{ errorMessage.personal_email }}
                                    </small>
                                </div>
                            </div>

                            <div class="field items-center">
                                <label class="label">Address
                                    <span class="text-red-500">*</span>
                                </label>
                                <div class="w-full">
                                    <input
                                        class="input"
                                        type="text" 
                                        placeholder="address"
                                        v-model="address"
                                    >   
                                    <small v-if="errorMessage.address" class="transition text-xxs text-red-400">
                                        {{ errorMessage.address }}
                                    </small>
                                </div>
                                
                            </div>

                            <div class="field items-center">
                                <label class="label">State
                                    <span class="text-red-500">*</span>
                                </label>
                                <div class="w-full">
                                    <input
                                        class="input"
                                        type="text" 
                                        placeholder="state"
                                        v-model="state"
                                    >   
                                    <small v-if="errorMessage.state" class="transition text-xxs text-red-400">
                                        {{ errorMessage.state }}
                                    </small>
                                </div>
                                
                            </div>
                        
                            <div class="field items-center">
                                <label class="label">LGA
                                    <span class="text-red-500">*</span>
                                </label>
                                <div class="w-full">
                                    <input
                                        class="input"
                                        type="text" 
                                        placeholder="lga"
                                        v-model="lga"
                                    >   
                                    <small v-if="errorMessage.lga" class="transition text-xxs text-red-400">
                                        {{ errorMessage.lga }}
                                    </small>
                                </div>
                                
                            </div>
                        </div>

                        <div class="mt-6">
                            <h3 class="text-sm md:text-xl font-bold pb-1 text-gray07">Bank account details</h3>

                            <div class="field items-center">
                                <label class="label">Bank name 
                                    <span class="text-red-500">*</span>
                                </label>
                                <select 
                                    class="select"
                                    placeholder="Select option"
                                    v-model="bank"
                                >
                                    <option value="" disabled selected>Select option</option>
                                    <option v-for="bank in allBanks" :key="bank.id" :value="bank">{{bank.name}}</option>
                                </select>
                                <small v-if="errorMessage.name" class="transition text-xxs text-red-400">
                                    {{ errorMessage.name }}
                                </small>
                            </div>

                            <div class="field items-center">
                                <label class="label">Account number 
                                    <span class="text-red-500">*</span>
                                </label>
                                <div class="w-full">
                                    <input
                                        class="input"
                                        type="text"
                                        maxlength="10"
                                        placeholder="012345678"
                                        v-model="account_number"
                                    >   
                                    <small v-if="errorMessage.account_number" class="transition text-xxs text-red-400">
                                        {{ errorMessage.account_number }}
                                    </small>
                                </div>
                            </div>

                            <div class="field items-center">
                                <label class="label">Account name 
                                    <span class="text-red-500">*</span>
                                </label>
                                <div class="w-full">
                                    <b-input placeholder="Loading" maxlength="10" v-model="account_name" :loading="isFetchingAccountName" disabled></b-input>
                                    <small v-if="errorMessage.account_name" class="transition text-xxs text-red-400">
                                        {{ errorMessage.account_name }}
                                    </small>
                                </div>
                            </div>
                        </div>

                        <div class="mt-6">
                            <h3 class="text-sm md:text-xl font-bold pb-1 text-gray07">Next of Kin Information</h3>

                            <div class="">
                                <div class="field items-center">
                                    <label class="label">NOK name  
                                    </label>
                                    <div class="w-full">
                                        <input 
                                            class="input" 
                                            type="text" 
                                            placeholder="Firstname lastname"
                                            v-model="nok_name"
                                        >   
                                        <small v-if="errorMessage.nok_name" class="transition text-xxs text-red-400">
                                            {{ errorMessage.nok_name }}
                                        </small>
                                    </div>
                                </div>

                                <div class="field items-center">
                                    <label class="label">NOK relationship
                                    </label>
                                    <div class="w-full">
                                        <select 
                                            class="select"
                                            placeholder="Select option"
                                            v-model="nok_relationship"
                                        >
                                            <option value="" disabled selected>Select option</option>
                                            <option value="spouse">Spouse</option>
                                            <option value="child">Child</option>
                                            <option value="parent">Parent</option>
                                            <option value="friend">Friend</option>
                                            <option value="relative">Relative</option>
                                            <option value="others">Others</option>
                                        </select>
                                        <small v-if="errorMessage.nok_relationship" class="transition text-xxs text-red-400">
                                            {{ errorMessage.nok_relationship }}
                                        </small>
                                    </div>
                                </div>

                                <div class="field items-center">
                                    <label class="label">NOK residential address
                                    </label>
                                    <div class="w-full">
                                        <input 
                                            class="input" 
                                            type="text" 
                                            placeholder="Enter address"
                                            v-model="nok_address"
                                        >   
                                        <small v-if="errorMessage.nok_address" class="transition text-xxs text-red-400">
                                            {{ errorMessage.nok_address }}
                                        </small>
                                    </div>
                                </div>

                                <div class="field items-center">
                                    <label class="label">NOK state of residence
                                    </label>
                                    <div class="w-full">
                                        <input 
                                            class="input" 
                                            type="text" 
                                            placeholder="Enter state"
                                            v-model="nok_state"
                                        >   
                                        <small v-if="errorMessage.state" class="transition text-xxs text-red-400">
                                            {{ errorMessage.nok_state }}
                                        </small>
                                    </div>
                                </div>

                                <div class="field items-center">
                                    <label class="label">NOK LGA of residence
                                    </label>
                                    <div class="w-full">
                                        <input 
                                            class="input" 
                                            type="text" 
                                            placeholder="Enter LGA of residence"
                                            v-model="nok_lga"
                                        >   
                                        <small v-if="errorMessage.lga" class="transition text-xxs text-red-400">
                                            {{ errorMessage.nok_lga }}
                                        </small>
                                    </div>
                                </div>

                                <div class="field items-center">
                                    <label class="label">NOK email address
                                    </label>
                                    <div class="w-full">
                                        <input 
                                            class="input" 
                                            type="text" 
                                            placeholder="Enter email address"
                                            v-model="nok_email"
                                        >   
                                        <small v-if="errorMessage.nok_email" class="transition text-xxs text-red-400">
                                            {{ errorMessage.nok_email }}
                                        </small>
                                    </div>
                                </div>

                                <div class="field items-center">
                                    <label class="label">NOK Phone no 
                                    </label>
                                    <div class="w-full">
                                        <div class="flex has-addon" style="width: 100%">
                                            <a class="addon">
                                            +234
                                            </a>
                                            <input 
                                                class="input"
                                                type="text" 
                                                maxlength="10"
                                                placeholder="8000000000"
                                                v-model="nok_phone"
                                            >
                                        </div>
                                        <small v-if="errorMessage.nok_phone" class="transition text-xxs text-red-400">
                                            {{ errorMessage.nok_phone }}
                                        </small>
                                    </div>
                                </div>
                            </div> 
                        </div>

                        <div class="mt-10">
                            <small v-if="errorMessage.all" class="transition text-xxs text-red-400 pb-4">
                                {{ errorMessage.all }}
                            </small>

                            <button
                                type = "submit"
                                class = "button is-primary text-base font-medium"
                            >
                                <span v-if="loadingStatus" class="is-submiting"></span>
                                <span v-else>Submit</span>
                            </button>
                        </div>
                    </form>
                </div>

                <div v-else >
                    <TokenInvalid />
                </div>
            </div>
            <Success v-else />
        </div>
    </div>
</template>

<script>
import { verifyIPPISToken, updateIPPISCustomer, resolveBank, getBanks } from "@/api/ippis";
import Success from "../components/Success";
import TokenInvalid from "@/components/TokenInvalid";


export default {
    components: {
        Success,
        TokenInvalid
    },

    data() {
        return {
            personal_email: '',
            state: '',
            lga: '',
            address: '',
            bank_name: '',
            account_number: '',
            account_name: '',
            bank_code: "",
            nok_name: '',
            nok_relationship: '',
            nok_address: '',
            nok_state: '',
            nok_lga: '',
            nok_email: '',
            nok_phone: '',
            errorMessage: {
                personal_email : "",
                nok_name : "",
                nok_relationship : "",
                nok_address : "",
                nok_email : "",
                nok_phone : "",
                all : ""
            },

            allBanks: [],
            bank: [],

            loadingStatus: false,
            loadingStatus1: false,
            isRegistrationSuccessful: false,
            isFetchingAccountName: false,
            isTokenValid: false
        }
    },

    watch: {
        account_number(value){
            if(value.length == 10 && this.bank){
                this.resolveAccount()
            }
        }
    },

    methods: {
        async resolveAccount(){
            try {
                this.isFetchingAccountName = true;
                const response = await resolveBank({
                    account_number: this.account_number, 
                    bank_code: this.bank.code, 
                }, this.$route?.query?.code);

                if (response.data.success) {
                    this.account_name = response.data.data.account_name
                
                } 

            } catch (err) {
                this.$toast.error(err.response.data.message, {
                    timeout: 5000
                })

            } finally {
                this.isFetchingAccountName = false;

            }
        },

        async getAllBanks(){
            try {
                const data = await getBanks()
                this.allBanks = data.data

            } catch (err) {
                this.$toast.error(err.response.data.message, {
                    timeout: 5000
                })
            };
        },

        validate() {
            this.errorMessage.personal_email = "";
            this.errorMessage.bank = "";
            this.errorMessage.account_number = "";
            this.errorMessage.nok_name = "";
            this.errorMessage.nok_relationship = "";
            this.errorMessage.nok_address = "";
            this.errorMessage.nok_state = "";
            this.errorMessage.nok_lga = "";
            this.errorMessage.nok_email = "";
            this.errorMessage.nok_phone = "";
        

            let data = [
                this.personal_email,
                this.address,
                this.state,
                this.lga,
                this.bank,
                this.account_number,
                this.account_name
            ]

            if ( data.some(item => item == '')) {
                this.errorMessage.all = "Please complete all asterisked fields";
                this.$toast.warning("Please complete all asterisked fields", {
                    timeout: 5000
                });

            } else {
                const format = /[a-zA-Z]+\s+[a-zA-Z]+/g;

                const mailformat = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

                if (!mailformat.test(this.personal_email)) {
                    this.errorMessage.personal_email = "Enter a valid email";

                } else if (this.bank.length ) {
                    this.errorMessage.bank = "Select a bank name";

                } else if (this.account_number.length !== 10) {
                    this.errorMessage.account_number = "Enter your 10 digit bank account number";

                } else if (!((/^\d+$/).test(this.account_number))) {
                    this.errorMessage.account_number = 'Enter a valid account number';
                
                } else if ((this.nok_name.length > 0) &&(!format.test(this.nok_name))) {
                    this.errorMessage.nok_name = "Provide your first name and surname";

                } else if ((this.nok_relationship.length > 0) && (this.nok_relationship.length < 5) ) {
                    this.errorMessage.nok_relationship = "Enter valid details";
                    
                }   else if ((this.nok_address.length > 0) && (this.nok_address.length < 10)) {
                    this.errorMessage.nok_address = "Enter a valid address";
                    
                }  else if ( (this.nok_email.length > 0) && (!mailformat.test(this.nok_email))) {
                    this.errorMessage.nok_email = "Enter a valid email";
                    
                }  else if ( (this.nok_phone.length > 0) && (this.nok_phone.length !== 10)) {
                    this.errorMessage.nok_phone = "Enter the last 10 digits of your phone number";

                } else if ((this.nok_phone.length > 0) && !((/^\d+$/).test(this.nok_phone))) {
                    this.errorMessage.nok_phone = 'Enter a valid phone number';
                }  else {
                    this.register();
                }
            }
        },

        async register() {

            let payload = {
                email: this.personal_email,
                address: this.address,
                state: this.state,
                lga: this.lga,
                account_number: this.account_number,
                bank_code: this.bank.code,
                account_name: this.account_name,
                bank_name: this.bank.name,

                nok_name: this.nok_name,
                nok_relationship: this.nok_relationship,
                nok_address: this.nok_address,
                nok_state: this.nok_state,
                nok_lga: this.nok_lga,
                nok_email: this.nok_email,
                nok_phone: this.nok_phone.length? '0'+this.nok_phone : '',
                
            }

            for (let field in payload) {
                if (payload[field] == '' || payload[field] == null || payload[field] == undefined ) {
                    delete payload[field];
                }
            }

            try {
                this.loadingStatus = true;
                const response = await updateIPPISCustomer(payload, this.$route?.query?.code);

                if (response.data.success) {
                    this.isRegistrationSuccessful = true
                
                }
            } catch (err) {
                this.$toast.error(err.response.data.message.errors[0].msg, {
                    timeout: 5000
                });

            } finally {
                this.loadingStatus = false;

            }
        },

        verifyToken(token) {
            this.loadingStatus1 = true;
            verifyIPPISToken(token)

            .then((res) => {
                if (res.data.success && res.data.data) {
                    this.isTokenValid = true
                    this.getAllBanks()

                }
            })
            
            .catch((error) => {
                this.$toast.error(`${ error.response.data.message }`, {
                    timeout: 5000
                });
            })
            this.loadingStatus1 = false;

        }
    },

    mounted() {
        this.verifyToken(this.$route?.query?.code)
    }

}
</script>

<style scoped>
.component-shadow {
    box-shadow: #daf1e3  0px 2px 4px 0px, #def5e6  0px 4px 12px 4px;
}

.page {
    background-color: white;
    font-family: 'Poppins';
}

.bg-radial {
  font-family: 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
  background: radial-gradient(100% 234.68% at 0% 100%, #F4FFF9 22.06%, #F4FFF9 88.31%);
}


@media screen and (min-width: 768px) {
    .fixed.w-screen {
    transform: translateX(-50%);
}
    .p-w {
        width: 80%;
    }
    .field {
        display: flex;
        width: 100%;
    }
    .label {
        width: 40%;
        display: flex;
        align-items: center;
    }

    .input, .has-addon {
        width: 60%;
    }
}

@media screen and (min-width: 920px) {
    .p-w {
        width: 65%;
    }
}
.input{
    font-size: 13px;
	height: 3.5em !important;
    padding-left: 18px;
    padding-right: 18px;
    background-color: hsl(0deg, 0%, 100%);
    border: 1px solid #8692A6;
    border-radius: 6px;
    color: #8692A6;
    max-width: 100%;
    width: 100%;
}

.input:focus, .select:focus{
    border-color: #26C165;
    box-shadow: 0 0 0 0.125em rgba(72, 199, 142, 0.25);
}

.has-addon .addon {
    border: 1px solid #8692A6;
    border-right: none;
    background-color: #f5f5f5;
    border-radius: 8px 0px 0px 8px;
    color: #8692A6;
    box-shadow: none;
    pointer-events: none;
    padding: 10px;
    font-size: 13px;
    font-weight: 500;
    display: flex;
    align-items: center;
}

.has-addon .input {
    border-radius: 0px 8px 8px 0px;
    border-left: none;
}

.select {
    color: #8692A6;
	width: 100%;
    font-size: 13px;
	height: 3.5em !important;
    padding-left: 18px;
    padding-right: 18px;
	background-color: white;
	border: 1px solid #8692a6;
	border-radius: 6px;
	outline: none;
	background-position: 95% 50%; 
}

.label {
    display: block;
    font-weight: 500 ;
    color: #696F79;
    font-size: 14px ;
    margin-bottom: 0.35rem
}

.field {
    margin-top: .85rem;
    margin-bottom: .85rem;
}

.button {
    border-radius: 8px;
    width: 100%;
    height: 3rem;
}

.is-submiting{
    animation: spinAround .5s infinite linear;
    border: 2px solid #ffffff;
    border-radius: 9999px;
    border-right-color: transparent;
    border-top-color: transparent;
    content: "";
    display: block;
    height: 1em;
    position: relative;
    width: 1em;
}

</style>