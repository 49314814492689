<template>
<svg :width="width" :height="width" viewBox="0 0 58 58" fill="none" xmlns="http://www.w3.org/2000/svg">
<path opacity="0.2" d="M29 50.75C41.0122 50.75 50.75 41.0122 50.75 29C50.75 16.9878 41.0122 7.25 29 7.25C16.9878 7.25 7.25 16.9878 7.25 29C7.25 41.0122 16.9878 50.75 29 50.75Z" fill="#26C165"/>
<path d="M38.9688 23.5625L25.677 36.25L19.0312 29.9062" stroke="#26C165" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M29 50.75C41.0122 50.75 50.75 41.0122 50.75 29C50.75 16.9878 41.0122 7.25 29 7.25C16.9878 7.25 7.25 16.9878 7.25 29C7.25 41.0122 16.9878 50.75 29 50.75Z" stroke="#26C165" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
</template>

<script>
export default {
  name: 'check',
  props: {
    width: {
        type: Number,
        default: 58
    }
  }
}
</script>