import sycamoreServiceClient from '.';

export const getTargetContribution = async offer_link => {
  const response = await sycamoreServiceClient({
    url: `/customer/target/contract/${offer_link}`,
    method: 'get',
  }, "___target");

  return {
    status: response.status,
    data: response.data,
  };
};

export const decideTargetContract = async (body, token) => {
  try {
    const response = await sycamoreServiceClient({
      url: "/customer/target/contract/decide",
      method: "post",
      data: body,
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    },  "___target");
    return Promise.resolve({
      status: response.status,
      data: response.data,
    });
  } catch (error) {
    return Promise.reject(error);
  }
};
