<template>
    <div>
        <div class="columns is-multiline">
            <div class="column is-12 my-12 px-12">
                
                <b-table
                    :data="customers"
                    :paginated="true"
                    :per-page="10"
                    :pagination-rounded="true"
                    :bordered="false"
                    :striped="true"
                    :hoverable="true"
                    detailed
                    detail-key="id"
                    detail-transition="fade"
                >
              
                    <b-table-column field="name" label="Name" v-slot="props">
                        {{ props.row.name}}
                    </b-table-column>

                    <b-table-column field="id" label="id" v-slot="props">
                        {{ props.row.id}}
                    </b-table-column>

                    <b-table-column field="email" width="250" label="Email address" v-slot="props">
                        {{ props.row.email }}
                    </b-table-column>

                    <b-table-column field="phone" width="250" label="Phone number" v-slot="props">
                        {{ props.row.phone }}
                    </b-table-column>
                    
                    <b-table-column field="button" label="Button" v-slot="props">
                        <p class="control">
                            <b-tooltip label="Open detail"
                                type="is-primary is-light"
                            >
                                <button
                                    @click="toggleModals(props.row.id)"
                                    class="button is-small"
                                >
                                    <span class="icon is-small">
                                        <i class="ri-list-settings-line ri-lg"></i>
                                    </span>
                                </button>
                            </b-tooltip>
                        </p>
                    </b-table-column>
                    
                    <template #detail="props">
                        <div class="card border-2 rounded-lg">
                            <div class="card-content">
                                <ul class="">
                                    <li class="flex justify-between text-xs pb-2 mb-2 border-b-2 text-gray-500">
                                        <span class="text-black">Date of birth</span>
                                        <span>{{ props.row.dob || 'None' }}</span>
                                    </li>

                                    <li class="flex justify-between text-xs pb-2 mb-2 border-b-2 text-gray-500">
                                        <span class="text-black">Gender</span>
                                        <span>{{ props.row.gender || 'None' }}</span>
                                    </li>

                                    <li class="flex justify-between text-xs pb-2 mb-2 border-b-2 text-gray-500">
                                        <span class="text-black">Address</span>
                                        <span>{{ props.row.address || 'None' }}</span>
                                    </li>
                                    
                                    <li class="flex justify-between text-xs pb-2 mb-2 border-b-2 text-gray-500">
                                        <span class="text-black">LGA</span>
                                        <span>{{ props.row.lga  || 'None' }}</span>
                                    </li>
                                    
                                    <li class="flex justify-between text-xs pb-2 mb-2 border-b-2 text-gray-500">
                                        <span class="text-black">state</span>
                                        <span>{{ props.row.state || 'None' }}</span>
                                    </li>
                                    
                                    <li class="flex justify-between text-xs pb-2 mb-2 border-b-2 text-gray-500">
                                        <span class="text-black">tag</span>
                                        <span>{{ props.row.tag || 'None' }}</span>
                                    </li>
                                    
                                    <li class="flex justify-between text-xs pb-2 mb-2 border-b-2 text-gray-500">
                                        <span class="text-black">country</span>
                                        <span>{{ props.row.country || 'None' }}</span>
                                    </li>
                                    
                                    <li class="flex justify-between text-xs pb-2 mb-2 border-b-2 text-gray-500">
                                        <span class="text-black">Nature of accomodation</span>
                                        <span>{{ props.row.nature_of_accomodation || 'None' }}</span>
                                    </li>
                                    
                                    <li class="flex justify-between text-xs pb-2 mb-2 border-b-2 text-gray-500">
                                        <span class="text-black">years_at_residence</span>
                                        <span>{{ props.row.years_at_residence || 'None' }}</span>
                                    </li>
                                    
                                    <li class="flex justify-between text-xs pb-2 mb-2 border-b-2 text-gray-500">
                                        <span class="text-black">marital_status</span>
                                        <span>{{ props.row.marital_status || 'None' }}</span>
                                    </li>
                                    
                                    <li class="flex justify-between text-xs pb-2 mb-2 border-b-2 text-gray-500">
                                        <span class="text-black">Bvn</span>
                                        <span>{{ props.row.bvn || 'None' }}</span>
                                    </li>
                                    
                                    <li class="flex justify-between text-xs pb-2 mb-2 border-b-2 text-gray-500">
                                        <span class="text-black">NIN</span>
                                        <span>{{ props.row.nin || 'None' }}</span>
                                    </li>

                                    <li class="flex justify-between text-xs pb-2 mb-2 border-b-2 text-gray-500">
                                        <span class="text-black">user type</span>
                                        <span>{{ props.row.user_type || 'None' }}</span>
                                    </li>

                                    <li class="flex justify-between text-xs pb-2 mb-2 border-b-2 text-gray-500">
                                        <span class="text-black">registration type</span>
                                        <span>{{ props.row.registration_type || 'None' }}</span>
                                    </li>

                                    <li class="flex justify-between text-xs pb-2 mb-2 border-b-2 text-gray-500">
                                        <span class="text-black">preferred monthly repayment date</span>
                                        <span>{{ props.row.preferred_monthly_repayment_date || 'None' }}</span>
                                    </li>

                                    <li class="flex justify-between text-xs pb-2 mb-2 border-b-2 text-gray-500">
                                        <span class="text-black">preferred weekly repayment date</span>
                                        <span>{{ props.row.preferred_weekly_repayment_date || 'None' }}</span>
                                    </li>

                                    <li class="flex justify-between text-xs pb-2 mb-2 border-b-2 text-gray-500">
                                        <span class="text-black">preferred daily repayment time</span>
                                        <span>{{ props.row.preferred_daily_repayment_time || 'None' }}</span>
                                    </li>

                                    <li class="flex justify-between text-xs pb-2 mb-2 border-b-2 text-gray-500">
                                        <span class="text-black">creditium Id</span>
                                        <span>{{ props.row.creditiumId || 'None' }}</span>
                                    </li>

                                    <li class="flex justify-between text-xs pb-2 mb-2 border-b-2 text-gray-500">
                                        <span class="text-black">group id</span>
                                        <span>{{ props.row.group_id || 'None' }}</span>
                                    </li>

                                    <li class="flex justify-between text-xs pb-2 mb-2 border-b-2 text-gray-500">
                                        <span class="text-black">user status</span>
                                        <span>{{ props.row.user_status || 'None' }}</span>
                                    </li>

                                    <li class="flex justify-between text-xs pb-2 mb-2 border-b-2 text-gray-500">
                                        <span class="text-black">email verified</span>
                                        <span>{{ props.row.email_verified || 'None' }}</span>
                                    </li>

                                    <li class="flex justify-between text-xs pb-2 mb-2 border-b-2 text-gray-500">
                                        <span class="text-black">phone_verified</span>
                                        <span>{{ props.row.phone_verified || 'None' }}</span>
                                    </li>

                                    <li class="flex justify-between text-xs pb-2 mb-2 border-b-2 text-gray-500">
                                        <span class="text-black">transaction pin</span>
                                        <span>{{ props.row.transaction_pin || 'None' }}</span>
                                    </li>

                                    <li class="flex justify-between text-xs pb-2 mb-2 border-b-2 text-gray-500">
                                        <span class="text-black">firebase_device_key</span>
                                        <span>{{ props.row.firebase_device_key || 'None' }}</span>
                                    </li>

                                    <li class="flex justify-between text-xs pb-2 mb-2 border-b-2 text-gray-500">
                                        <span class="text-black">signup_platform</span>
                                        <span>{{ props.row.signup_platform || 'None' }}</span>
                                    </li>

                                    <li class="flex justify-between text-xs pb-2 mb-2 border-b-2 text-gray-500">
                                        <span class="text-black">alt_phone</span>
                                        <span>{{ props.row.alt_phone || 'None' }}</span>
                                    </li>

                                    <li class="flex justify-between text-xs pb-2 mb-2 border-b-2 text-gray-500">
                                        <span class="text-black">alt_email</span>
                                        <span>{{ props.row.alt_email || 'None' }}</span>
                                    </li>

                                    <li class="flex justify-between text-xs pb-2 mb-2 border-b-2 text-gray-500">
                                        <span class="text-black">credit_clan_id</span>
                                        <span>{{ props.row.credit_clan_id || 'None' }}</span>
                                    </li>

                                </ul>

                            </div>
                        </div>
                    </template>
                </b-table>
            </div>
        </div>

        <b-sidebar
            type="is-light"
            :fullheight="fullheight"
            :overlay="overlay"
            :right="right"
            :can-cancel="cancancel"
            v-model="open"
        >
            <div class="tw-p-4">
                <button
                    @click="open = false"
                    class="delete is-large has-background-black"
                >
                </button>

                <side-panel @clicked="onClickChild" :customerDetails="customerDetails" />
            </div>
        </b-sidebar>
    </div>
</template>

<script>
import axios from 'axios'
import SidePanel from '@/components/SidePanel.vue'

    export default {
        components:{
                SidePanel,
        },
        data() {
            return {
                MIGRATION_URL: process.env.VUE_APP_MIGRATION_URL,
                isCardModalActive: false,
                customers:[],
                customerDetails:[],
                currentCustomer:'',
                component: '',

                open: false,
                overlay: false,
                fullheight: true,
                right: true,
                cancancel: ['escape'],
                isLoading: false,
            }
        },

        computed:{
        },

        methods:{
            cardModal() {
                this.$buefy.modal.open({
                    parent: this,
                    component: ModalForm,
                    hasModalCard: true,
                    customClass: 'custom-class custom-class-2',
                    trapFocus: true
                })
            },

            openModal(prop, modal){
                this.isCardModalActive= true
                this.currentData=prop
                this.component=modal
            },

            toggleModals(id) {
                axios.get(process.env.VUE_APP_ROOT_API+`/customers/${id}`)

                .then(response => {
                    this.customerDetails = response.data.data;
                    this.open = true;
                })

                .catch(e => {
                })
            },

            fetchAllCustomers(){
                axios.get(process.env.VUE_APP_ROOT_API+`/customers`)
                .then(response => {
                    this.customers = response.data.data
                })
                .catch(e => {
                    this.errors.push(e)
                })
            },

            onClickChild (value) {
                this.fetchAllCustomers()
            }
        },

        mounted(){
            this.fetchAllCustomers()
        }
        

    }
</script>